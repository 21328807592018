import AuthService from "@/services/AuthService";
import { HumaniDate } from "@hx/dates";
import { Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ContentBlock } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import SearchRefinements from "@/components/SearchRefinements";
import SimplePagination from "@/components/SimplePagination";
import { open } from "@/state/actions/dropdownActions";
import { getReport, getReportCsv } from "@/state/actions/globalReportActions";
import { sendOrder } from "@/state/actions/orderActions";
import { updateSearch } from "@/state/actions/searchActions";
import { HelpIds } from "@/utils/Help";
import NewManualOrder from "./NewManualOrder";
import OrderActions from "./OrderActions";
import { ResendOrderModal } from "./ResendOrderModal";

const CUSTOM_TAX_KEY = "customTax";

class Orders extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
	}

	load(page = 1) {
		const { getReport } = this.props;
		getReport("order", page);
	}

	loadPage(page) {
		const { updateSearch } = this.props;
		updateSearch({ page }, true);
	}

	downloadCsv() {
		const { getReportCsv } = this.props;
		getReportCsv("order");
	}

	getFilterOptions() {
		const {
			currentEvent: {
				event: { dates, datesUseCalendar, location, timezone }
			},
			userRole
		} = this.props;

		const orderStatusOptions = [
			...(userRole === "admin"
				? [
						{ value: "all", name: "All" },
						{ value: "pending", name: "Pending" }
				  ]
				: []),
			{ value: "complete", name: "Complete" },
			{ value: "incomplete", name: "Incomplete" },
			{ value: "donations", name: "Donations" }
		];

		return [
			{
				key: "eventDateIds",
				label: "Event Dates",
				mode: "multiple",
				kind: datesUseCalendar ? "eventDatePicker" : "select",
				placeholder: "All dates",
				options: dates.map((date) => {
					return {
						...(datesUseCalendar ? date : {}),
						value: date._id,
						name: new HumaniDate(date.startDate, timezone, location).formatting.dateRange(
							new HumaniDate(date.endDate, timezone, location)
						)
					};
				})
			},
			{
				key: "orderStatus",
				label: "Order status",
				kind: "radio",
				options: orderStatusOptions
			},
			{
				key: "manualOrder",
				label: "Order type",
				kind: "radio",
				options: [
					{ value: undefined, name: "All" },
					{ value: "true", name: "Manual" },
					{ value: "false", name: "Online" }
				]
			},
			{
				key: "financialStatus",
				label: "Financial status",
				kind: "radio",
				options: [
					{ value: "all", name: "  All" },
					{ value: "free", name: "Free" },
					{ value: "notApplicable", name: "Not Applicable" },
					{ value: "partiallyRefunded", name: "Partially Refunded" },
					{ value: "refunded", name: "Refunded" },
					{ value: "paid", name: "Paid" }
				]
			},
			{
				key: "total",
				label: "Sales range",
				kind: "numberRange"
			}
		];
	}

	render() {
		const { currentEvent, userRole } = this.props;
		const { results, loading, page, error, csvLoading } = this.props.reportState;
		const messages = loading ? { emptyText: "Loading" } : { emptyText: "No Orders" };

		const insuranceEnabled = currentEvent?.event?.settings?.isInsuranceEnabled;

		let columns = [
			{
				title: "Order ID",
				dataIndex: "orderName",
				width: 100,
				key: "orderName",
				fixed: "left",
				render: (orderName, record) => {
					const link = record.userId
						? `/console/promote/gift-cards/order/${record._id}`
						: `/console/my-events/${record.eventId}/orders/${record._id}`;
					return <Link to={link}>{orderName}</Link>;
				}
			},
			{
				title: "Event date",
				dataIndex: "date",
				key: "date"
			},
			{
				title: "Event time",
				dataIndex: "time",
				key: "time"
			},
			{
				title: "First name",
				dataIndex: "firstName",
				key: "firstName"
			},
			{
				title: "Last name",
				dataIndex: "lastName",
				key: "lastName"
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email"
			},
			{
				title: "Mobile",
				dataIndex: "mobile",
				key: "mobile"
			},
			{
				title: "Order date & time",
				dataIndex: "orderDate",
				key: "orderDate"
			},
			{
				title: "Type",
				dataIndex: "orderType",
				key: "orderType"
			},
			{
				title: "Financial status",
				dataIndex: "financialStatus",
				key: "financialStatus"
			},
			{
				title: "Valid tickets",
				dataIndex: "completeTickets",
				key: "completeTickets"
			},
			{
				title: "Cancelled tickets",
				dataIndex: "cancelledTickets",
				key: "cancelledTickets"
			},
			{
				title: "Ticket sales",
				dataIndex: "ticketSales",
				key: "ticketSales"
			},
			{
				title: "Add-on sales",
				dataIndex: "addOnSales",
				key: "addOnSales"
			},
			{
				title: "Donation",
				dataIndex: "additionalDonations",
				key: "additionalDonations"
			},
			{
				title: "Fee rebate",
				dataIndex: "feeRebate",
				key: "feeRebate"
			},
			{
				title: "Humanitix passed-on fees",
				dataIndex: "humanitixFeePassedOn",
				key: "humanitixFeePassedOn"
			},
			{
				title: "Amex surcharge",
				dataIndex: "amexSurcharge",
				key: "amexSurcharge"
			},
			{
				title: "Custom Tax",
				dataIndex: "customTax",
				key: CUSTOM_TAX_KEY
			},
			...(AuthService.showInsuranceColumn() && insuranceEnabled
				? [
						{
							title: "Refund protection",
							dataIndex: "insurance",
							key: "insurance"
						}
				  ]
				: []),
			{
				title: "Paid",
				dataIndex: "paid",
				key: "paid"
			},
			{
				title: "Humanitix absorbed fees",
				dataIndex: "humanitixFeeAbsorbed",
				key: "humanitixFeeAbsorbed"
			},
			{
				title: "Zip fee (absorbed)",
				dataIndex: "zipFee",
				key: "zipFee"
			},
			{
				title: "Afterpay fee (absorbed)",
				dataIndex: "afterpayFee",
				key: "afterpayFee"
			},
			{
				title: "Refunds",
				dataIndex: "refunds",
				key: "refunds"
			},
			{
				title: "Your Earnings",
				dataIndex: "earnings",
				key: "earnings"
			},
			...(userRole === "admin"
				? [
						{
							title: "Your Payout",
							dataIndex: "payout",
							key: "payout"
						}
				  ]
				: []),
			{
				title: "Refunded fees",
				dataIndex: "refundedFees",
				key: "refundedFees"
			},
			{
				title: "Giftcard used",
				dataIndex: "giftcardCredit",
				key: "giftcardCredit"
			},
			{
				title: "Voucher used",
				dataIndex: "voucher",
				key: "voucher"
			},
			{
				title: "Discount code used",
				dataIndex: "discountCode",
				key: "discountCode"
			},
			{
				title: "Discount redeemed",
				dataIndex: "discountValue",
				key: "discountValue"
			},
			{
				title: "Tax on sales",
				dataIndex: "taxOnSales",
				key: "taxOnSales"
			},
			{
				title: "Tax on rebates",
				dataIndex: "taxOnRebates",
				key: "taxOnRebates"
			},
			{
				title: "Tax on booking fees",
				dataIndex: "taxOnBookingFees",
				key: "taxOnBookingFees"
			},
			{
				title: "Gateway",
				dataIndex: "paymentGateway",
				key: "paymentGateway"
			},
			{
				title: "Marketing opt-in",
				dataIndex: "marketingOptIn",
				key: "marketingOptIn"
			},
			{
				title: "Notes",
				dataIndex: "notes",
				key: "notes"
			},
			{
				title: "Status",
				dataIndex: "orderStatus",
				key: "orderStatus"
			}
		];

		columns = columns.concat([
			{
				title: "Action",
				key: "action",
				fixed: "right",
				width: 100,
				render: (_, record) => {
					return <OrderActions eventId={currentEvent.event._id} event={currentEvent.event} order={record} />;
				}
			}
		]);

		const customTaxName = currentEvent?.event?.paymentOptions?.customSalesTaxSettings?.enabled
			? currentEvent.event.paymentOptions.customSalesTaxSettings.name
			: null;

		return (
			<LoadErrorView loading={loading} error={error} retryAction={this.load}>
				<PageHeading
					title="Orders"
					helpButton={{
						link: HelpIds.orders,
						title: "Help - Orders"
					}}
				/>
				<ContentBlock style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}>
					<SearchRefinements
						defaultSearch={{
							eventIds: [currentEvent.event._id],
							...(userRole === "admin" ? { orderStatus: "complete" } : {})
						}}
						load={this.load}
						showRefinements
						filterOptions={this.getFilterOptions()}
						dropdownId="eventOrders"
						download={{
							download: this.downloadCsv,
							loading: csvLoading,
							tooltip: "Export orders"
						}}
						extras={
							<>
								<NewManualOrder event={currentEvent.event} />
							</>
						}
					/>
					<SimplePagination pageAction={this.loadPage} currentPage={page} />
					<div style={{ minWidth: "100%", overflowX: "auto" }}>
						<Table
							columns={columns.reduce((acc, column) => {
								if (column.key !== CUSTOM_TAX_KEY) {
									return [...acc, column];
								}
								if (currentEvent.event.location === "US" && customTaxName) {
									return [...acc, { ...column, title: customTaxName }];
								}
								return acc;
							}, [])}
							dataSource={results}
							rowKey="_id"
							locale={messages}
							scroll={{ x: "max-content" }}
							pagination={false}
						/>
					</div>
					<SimplePagination pageAction={this.loadPage} currentPage={page} />
				</ContentBlock>
				<ResendOrderModal />
				<ExportBar buttonText="Export Orders" buttonClick={this.downloadCsv} loading={csvLoading} />
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		reportState: state.globalReportState,
		actionsLoading: state.order.actionsLoading,
		userRole: state.auth.user.role
	}),
	{
		updateSearch,
		getReport,
		getReportCsv,
		sendOrder,
		open
	}
)(Orders);
