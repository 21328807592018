import { Component } from "react";
import { connect } from "react-redux";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { loadSDK } from "@/state/actions/facebookActions";
import { HelpIds } from "@/utils/Help";
import ConnectFacebook from "./ConnectFacebook";
import PostToFacebook from "./PostToFacebook";

class FacebookEvent extends Component {
	componentDidMount() {
		const { loadSDK, facebook } = this.props;
		if (!facebook.sdk.loaded && !facebook.sdk.loading) {
			loadSDK();
		}
	}
	render() {
		const { facebookSettings, facebook, currentEvent } = this.props;
		const facebookConnected = !!(facebookSettings && facebookSettings.connection && facebookSettings.connection.userId);
		let pageAccess = true;
		if (facebook.account.loaded) {
			const facebookEvent =
				currentEvent.event.facebookEvents && currentEvent.event.facebookEvents.length
					? currentEvent.event.facebookEvents[0]
					: false;
			const page =
				facebookEvent && facebookEvent.pageId
					? facebook.account.pages.find((page) => page.id === facebookEvent.pageId)
					: null;
			if (facebookEvent && facebookEvent.pageId && facebookEvent.facebookId && !page) {
				// the user is connected but does not have pageAccess
				pageAccess = false;
			}
		}
		return (
			<LoadErrorView loading={facebook.sdk.loading || facebook.connectionCheck.loading}>
				<PageHeading
					title="Facebook Event"
					helpButton={{
						link: HelpIds.facebookEvent,
						title: "Help - Facebook Event"
					}}
				/>
				{!facebookConnected || !pageAccess ? <ConnectFacebook pageAccess={pageAccess} /> : null}
				{facebookConnected && pageAccess ? <PostToFacebook /> : null}
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => {
		return {
			facebook: state.facebook,
			currentEvent: state.currentEvent,
			facebookSettings: state.auth.user.facebookSettings
		};
	},
	{ loadSDK }
)(FacebookEvent);
