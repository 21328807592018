import LoadErrorView from "@/components/LoadErrorView";
import { getUserEvents } from "@/state/actions/eventActions";
import { getTour } from "@/state/actions/tourActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { FC, useEffect } from "react";
import { useParams } from "react-router";
import { TourForm } from "./TourForm";

const Tour: FC = () => {
	const params = useParams();

	const tourId = params?.tourId ?? "";
	const isNewTour = tourId === "new";

	const dispatch = useAppDispatch();

	const userId = useAppSelector((state) => state.auth.user.id);
	const userEvents = useAppSelector((state) => state.userEvents?.events ?? []);
	const tours = useAppSelector((state) => state.tours);

	const { error, loading } = tours.tour;

	const _load = () => {
		if (!isNewTour) {
			dispatch(getTour(tourId));
		}
		if (!userEvents.length) {
			dispatch(getUserEvents(userId, "name _id"));
		}
	};

	useEffect(() => {
		_load();
	}, []);

	if (loading) {
		return <LoadErrorView loading={loading} error={error} retryAction={() => _load()} />;
	}

	return <TourForm tourId={tourId} />;
};

export default Tour;
