import LocationService from "../../services/LocationService";
import store from "../../state/store";

export const USER_LOCATION_SET = "USER_LOCATION_SET";
export const EVENT_LOCATION_SET = "EVENT_LOCATION_SET";
export const OVERRIDE_LOCATION_SET = "OVERRIDE_LOCATION_SET";

export const onUserLocationSet = (location) => {
	return (dispatch) => {
		LocationService.setUserLocation(location);
		dispatch({ type: USER_LOCATION_SET, location });
	};
};

export const onEventLocationSet = (location, callback) => {
	return (dispatch) => {
		LocationService.setEventLocation(location);
		dispatch({ type: EVENT_LOCATION_SET, location });
		if (callback) {
			callback();
		}
	};
};

export const onOverrideLocationSet = (location) => {
	return (dispatch) => {
		dispatch({
			type: OVERRIDE_LOCATION_SET,
			location
		});
	};
};

export const getLocationFromState = (isDefaultUser) => {
	const { locations, auth } = store.getState();
	const locationLevel = isDefaultUser ? "userLevelLocation" : "eventLevelLocation";
	if (!locations.overrideLocation) {
		return locations[locationLevel] || auth.user.location;
	}
	return locations.overrideLocation;
};
