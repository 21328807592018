import accessIcon from "@/assets/accessibility/access.svg";
import assistiveListeningSystemsIcon from "@/assets/accessibility/assistiveListeningSystems.svg";
import audioDescriptionIcon from "@/assets/accessibility/audioDescription.svg";
import brailleIcon from "@/assets/accessibility/braille.svg";
import ccIcon from "@/assets/accessibility/cc.svg";
import largePrintIcon from "@/assets/accessibility/largePrint.svg";
import ocIcon from "@/assets/accessibility/oc.svg";
import signIcon from "@/assets/accessibility/sign.svg";
import telephoneIcon from "@/assets/accessibility/telephone.svg";
import typewritterIcon from "@/assets/accessibility/typewritter.svg";
import wheelchairIcon from "@/assets/accessibility/wheelchair.svg";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { H1, H3, P } from "@/components/text/Text";
import { usePrompt } from "@/hooks/usePrompt";
import { saveEvent } from "@/state/actions/eventActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Button } from "@/ui";
import styled from "@emotion/styled";
import { Checkbox, Form } from "antd/lib";
import { FC } from "react";
import { useParams } from "react-router";

const features = [
	{
		name: "access",
		label: "Access",
		description:
			"This symbol indicates access for people who are blind or have low vision, best used in places such as: a guided tour, a path to a nature trail or a scent garden in a park; and a tactile tour or a museum exhibition that may be touched.",
		icon: accessIcon
	},
	{
		name: "wheelchairAccessibility",
		label: "Wheelchair Accessibility",
		description:
			"The wheelchair symbol indicates access for individuals with limited mobility, including wheelchair users. Remember that a ramped entrance is not completely accessible if there are no curb cuts, and an elevator is not accessible if it can only be reached via steps.",
		icon: wheelchairIcon
	},
	{
		name: "audioDescription",
		label: "Audio Description",
		description:
			"Blind or low vision people may enjoy performing arts, visual arts, television, video, and film that offers live commentary or narration (via headphones and a small transmitter) of visual elements provided by a trained Audio Describer.",
		icon: audioDescriptionIcon
	},
	{
		name: "telephoneTypewriter",
		label: "Telephone Typewriter",
		description:
			"This device is also known as a text telephone (TT), or telecommunications device for the deaf (TDD). TTY indicates the presence of a device used with the telephone for communication with and between deaf, hard of hearing, speech impaired and/or hearing, persons.",
		icon: typewritterIcon
	},
	{
		name: "volumeControlTelephone",
		label: "Volume Control Telephone",
		description:
			"This symbol indicates the presence of telephones that have handsets with amplified sound and/or adjustable volume controls.",
		icon: telephoneIcon
	},
	{
		name: "assistiveListeningSystems",
		label: "Assistive Listening Systems",
		description: "These systems transmit amplified sound via hearing aids, headsets or other devices",
		icon: assistiveListeningSystemsIcon
	},
	{
		name: "signLanguageInterpretation",
		label: "Sign Language Interpretation",
		description:
			"The symbol indicates that Sign Language Interpretation is provided for a lecture, tour, film, performance, conference or other program.",
		icon: signIcon
	},
	{
		name: "accessiblePrint",
		label: "Accessible Print (18 pt. or Larger)",
		description:
			"Large print is indicated by the words: “Large Print,” printed in 18 pt. or larger text. In addition to identifying large print versions of books, pamphlets, museum guides and theater programs, you may use the symbol on conference or membership forms with large print. Sans serif or modified serif print with high contrast is important, and special attention should be paid to letter and word spacing.",
		icon: largePrintIcon
	},
	{
		name: "closedCaptioning",
		label: "Closed Captioning (CC)",
		description:
			"Closed Captioning (CC) (commonly known as subtitles) enables people who are deaf or hard of hearing to read a transcript of the audio portion of a video, film, exhibition or other presentation. As the video plays, text captions transcribe (although not always verbatim) speech and other relevant sounds.",
		icon: ccIcon
	},
	{
		name: "openedCaptioning",
		label: "Opened Captioning (OC)",
		description:
			"This symbol indicates that captions, which translate dialogue and other sounds in print, are displayed on the videotape, movie, television program or exhibit audio. Open Captioning is preferred by many, including deaf and hard-of-hearing individuals, and people whose second language is English. It also helps teach children how to read and keep sound levels to a minimum in museums and restaurants.",
		icon: ocIcon
	},
	{
		name: "brailleSymbol",
		label: "Braille Symbol",
		description:
			"This symbol indicates that printed material is available in Braille, including exhibition labeling, publications and signage.",
		icon: brailleIcon
	}
];

const FeatureInfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: var(--spacing-md);
	margin-left: var(--spacing-md);

	@media (max-width: 600px) {
		flex-direction: column;
		margin-left: 20px;
		align-items: flex-start;
	}
`;

type FeatureInfoProps = {
	label: string;
	description: string;
	icon: string;
};

const FeatureInfo: FC<FeatureInfoProps> = ({ label, description, icon }) => {
	return (
		<FeatureInfoWrapper>
			<img src={icon} height={100} alt={`${label}-image`} />
			<div>
				<H3>{label}</H3>
				<P style={{ maxWidth: "unset" }}>{description}</P>
			</div>
		</FeatureInfoWrapper>
	);
};

const StyledForm = styled(Form)`
	.ant-form-item {
		margin-bottom: 8px;
	}
`;

const FormItemWrapper = styled.div`
	padding: var(--spacing-xxl);
	border: 1px solid var(--border-color1);
	border-radius: var(--rounded-md);
	background-color: var(--surface1);
	transition: var(--border-transition);

	&:hover {
		border: var(--border1-hover);
	}
`;

export const Features = () => {
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const dispatch = useAppDispatch();
	const params = useParams();
	const eventId = params?.eventId;

	const [featuresForm] = Form.useForm();
	const { resetFormDirty } = usePrompt("You have unsaved changes. Are you sure you want to leave?", featuresForm);

	const save = async () => {
		const formValues = featuresForm.getFieldsValue(true);
		await dispatch(saveEvent(eventId, { accessibility: { features: formValues } }));
	};

	return (
		<LoadErrorView
			loading={currentEvent.save.loading}
			error={currentEvent.save?.error ? { title: "Oops", message: currentEvent.save?.error as string } : undefined}
		>
			<H1>Accessibility</H1>
			<P style={{ marginTop: "-16px" }}>Indicate what accessibility features your event provides</P>
			<StyledForm
				autoComplete="off"
				form={featuresForm}
				initialValues={currentEvent.event?.accessibility?.features}
				layout="vertical"
				style={{ width: "100%", marginTop: "var(--spacing-xxl)" }}
				onFinish={resetFormDirty}
			>
				<div style={{ display: "flex", flexDirection: "column", gap: "var(--spacing-md)", marginBottom: 36 }}>
					{features.map((f, idx) => {
						return (
							<FormItemWrapper>
								<Form.Item key={`${f.name}-${idx}`} name={f.name} valuePropName="checked">
									<Checkbox>
										<FeatureInfo label={f.label} description={f.description} icon={f.icon} />
									</Checkbox>
								</Form.Item>
							</FormItemWrapper>
						);
					})}
				</div>
				<FormBar>
					<Button
						style={{
							float: "right"
						}}
						variant="primary"
						htmlType="submit"
						aria-label="Save"
						onClick={save}
					>
						Save
					</Button>
				</FormBar>
			</StyledForm>
		</LoadErrorView>
	);
};
