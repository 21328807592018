import { css } from "@emotion/css";
import { Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "../components/AppBlocks";
import IconButton from "../components/IconButton";
import LoadErrorView from "../components/LoadErrorView";
import PageHeading from "../components/PageHeading";
import SearchRefinements from "../components/SearchRefinements";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import TableActions from "../components/table/TableActions";
import { clearTag, deleteTag, findTag, findTags, saveTag } from "../state/actions/tagActions";
import TagModal from "./TagModal";

const header = css({
	display: "flex",
	justifyContent: "space-between"
});

class Tags extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
	}

	state = {
		showModal: false
	};

	load() {
		const { findTags, search } = this.props;
		findTags(search);
	}

	editTag = (id) => {
		const { findTag } = this.props;
		findTag(id);
		this.setState({
			showModal: true
		});
	};

	delete = (id) => {
		const { deleteTag } = this.props;
		deleteTag(id);
	};

	toggleModal = () => {
		const { clearTag } = this.props;
		const { showModal } = this.state;
		clearTag();
		this.setState({
			showModal: !showModal
		});
	};

	saveFormTag = (values) => {
		const { saveTag } = this.props;
		saveTag(values);
		this.setState({
			showModal: false
		});
	};

	render() {
		const { showModal } = this.state;
		const { tags, auth } = this.props;

		const columns = [
			{
				title: "Name",
				dataIndex: "name",
				key: "name"
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				fixed: "right",
				width: 100,
				render: (_, tag) => {
					if (auth.user.id === tag.userId) {
						return (
							<TableActions>
								<IconButton
									icon="edit"
									ariaLabel="Edit"
									tooltip="Edit"
									showTooltip
									onClick={() => this.editTag(tag._id)}
								/>

								<IconButton
									icon="delete"
									tooltip="Delete"
									ariaLabel="Delete"
									showTooltip
									onClick={() => this.delete(tag._id)}
								/>
							</TableActions>
						);
					}
				}
			}
		];

		return (
			<div>
				<PageHeading title="Tags" />
				<ContentBlock>
					<LoadErrorView loading={tags.loading} error={tags.error} retryAction={this.load}>
						<div className={header}>
							<SearchRefinements style={{ marginBottom: 12 }} load={this.load} />
							<LegacyButton
								icon={{ left: true, name: "plus" }}
								type="action"
								ariaLabel="Add Tag"
								style={{ maxHeight: 36 }}
								onClick={this.toggleModal}
							>
								Add Tag
							</LegacyButton>
						</div>
						<Table columns={columns} rowKey="_id" dataSource={tags.tags} />

						<TagModal visible={showModal} handleCancel={this.toggleModal} save={this.saveFormTag} />
					</LoadErrorView>
				</ContentBlock>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		tags: state.tags,
		search: state.search,
		auth: state.auth
	}),
	{
		saveTag,
		findTags,
		deleteTag,
		findTag,
		clearTag
	}
)(Tags);
