import { Button, DateTimePicker, FormItemRow, FormLabel, RadioGroup, ResponsiveModal } from "@/ui";
import { Drawer, Flex, Form, Input, InputNumber, Select, Switch, message } from "@/ui/antd";
import { FC, useEffect, useState } from "react";
import { useViewport } from "@/hooks/useViewport";
import { trpc } from "@/trpc";
import { FormItem } from "@/ui/FormItem";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { PaymentPlanSettingSchema } from "@hx/console-shared";
import { Rule } from "antd/es/form";
import { useWatch } from "antd/es/form/Form";
import { getCurrencySymbol } from "@/utils/Format";

const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const Description = styled.p`
	margin-bottom: 8px;
	max-width: 600px;
`;

const CurvedInputNumber = styled(InputNumber)`
	border-radius: 4px;
	min-height: 36px;
	min-width: 180px;
`;

const antFormItemNoColon = css({
	".ant-form-item-required:after": {
		display: "none"
	}
});

type PaymentPlanFormProps = {
	uiController: {
		isCreateModalOpen: boolean;
		toggleCreateModal: (value: boolean) => void;
		isDrawerOpen: boolean;
		toggleDrawer: (value: boolean) => void;
	};
	initialValues: PaymentPlanSettingSchema | null;
	eventId: string;
	eventTimezone: string;
};

export const PaymentPlanForm: FC<PaymentPlanFormProps> = ({ uiController, initialValues, eventId, eventTimezone }) => {
	const paymentPlanUpsertingProcedure = trpc.paymentPlans.upsertPlan.useMutation();
	const [paymentPlanForm] = Form.useForm<PaymentPlanSettingSchema>();
	const currFormValues = useWatch([], paymentPlanForm);
	const [editNameFieldIsOpen, toggleEditNameField] = useState(false);
	const [lastConfirmedName, setLastConfirmedName] = useState(initialValues?.name);
	const { isMobile } = useViewport();
	const eventCurrencySymbol = getCurrencySymbol();

	useEffect(() => {
		if (uiController.isDrawerOpen) {
			const name = currFormValues.name ? currFormValues.name : initialValues?.name;
			paymentPlanForm.setFieldsValue({ ...initialValues, name });
			setLastConfirmedName(name);
		}
	}, [uiController.isDrawerOpen]);

	const validateNameAndOpenFormDrawer = async () => {
		const formValues = await paymentPlanForm.validateFields();
		if (formValues) {
			paymentPlanForm.setFieldsValue(formValues);
			uiController.toggleCreateModal(false);
			uiController.toggleDrawer(true);
		}
	};

	const onSavePlan = async () => {
		try {
			const formValues = await paymentPlanForm.validateFields();
			if (formValues) {
				const response = await paymentPlanUpsertingProcedure.mutateAsync({ eventId, paymentPlan: formValues });
				if (response.success) {
					message.success("Payment plan created successfully");
					uiController.toggleDrawer(false);
					paymentPlanForm.resetFields();
				} else {
					message.error("Failed to create payment plan");
				}
			} else {
				message.error("Failed to create payment plan");
			}
		} catch (e) {
			message.error("Failed to create payment plan");
		}
	};

	const onCancel = () => {
		uiController.toggleCreateModal(false);
		uiController.toggleDrawer(false);
		paymentPlanForm.resetFields();
	};

	const discardNameChange = () => {
		paymentPlanForm.setFieldValue("name", lastConfirmedName);
		toggleEditNameField(false);
	};

	const confirmNameChange = () => {
		if (currFormValues.name) {
			setLastConfirmedName(currFormValues.name);
			toggleEditNameField(false);
		}
	};

	const daysRules = [
		{
			required: true,
			message: "Days is required",
			type: "number"
		} as Rule,
		{
			pattern: /^[0-9]*$/,
			message: "Days must be a whole number"
		} as Rule
	];

	const hoursRules = [
		{ required: true, message: "Hours is required" },
		{
			pattern: /^[0-9]*$/,
			message: "Hours must be a whole number"
		} as Rule,
		{
			type: "number",
			max: 24,
			message: "Hours should be less than 24"
		} as Rule
	];

	return (
		<Form
			form={paymentPlanForm}
			layout="vertical"
			style={{
				width: "100%"
			}}
		>
			<ResponsiveModal
				open={uiController.isCreateModalOpen}
				children={
					<FormItem
						name="name"
						label={<b>Plan name</b>}
						rules={[{ required: true, min: 1, type: "string", message: "A name is required" }]}
						hidden={uiController.isDrawerOpen}
						className={antFormItemNoColon}
					>
						<Input maxLength={80} />
					</FormItem>
				}
				closable
				onCancel={onCancel}
				footer={
					<Footer>
						<Button onClick={validateNameAndOpenFormDrawer} variant="secondary">
							Create plan
						</Button>
					</Footer>
				}
				padding="24px"
				header="Name your plan"
				width="672px"
			/>
			{uiController.isDrawerOpen && (
				<Drawer
					placement="bottom"
					height="100%"
					open={uiController.isDrawerOpen}
					title={
						<Flex gap={isMobile ? "0px" : "5px"} vertical={isMobile}>
							<Form.Item name="_id" hidden>
								<Input />
							</Form.Item>
							<FormItem
								name="name"
								rules={[{ required: true, min: 1, type: "string", message: "A name is required" }]}
								hidden={!editNameFieldIsOpen}
								style={{ marginBottom: 0 }}
							>
								<Input maxLength={80} />
							</FormItem>
							{editNameFieldIsOpen ? (
								<Flex gap="2px">
									<Button variant="tertiary" onClick={discardNameChange} iconOnly>
										<CloseOutlined />
									</Button>
									<Button variant="tertiary" disabled={!currFormValues.name} onClick={confirmNameChange} iconOnly>
										<CheckOutlined />
									</Button>
								</Flex>
							) : (
								<Button
									variant="text"
									onClick={() => toggleEditNameField(true)}
									iconRight={<EditOutlined />}
									style={{
										marginRight: isMobile ? "10px" : "auto"
									}}
								>
									{currFormValues.name}
								</Button>
							)}
						</Flex>
					}
					onClose={onCancel}
					extra={
						<Flex gap={isMobile ? "4px" : "16px"}>
							<Button variant="tertiary" onClick={onCancel}>
								Close
							</Button>
							<Button onClick={onSavePlan} variant="secondary" type="submit" fullWidth>
								Save
							</Button>
						</Flex>
					}
					closeIcon={null}
				>
					<FormItemRow noMargin style={{ maxWidth: 170 }}>
						<FormLabel noMargin tooltip="Once enabled, attendees can enrol in the payment plan">
							Enabled
						</FormLabel>
						<FormItem initialValue={false} name="enabled" style={{ marginBottom: 8 }}>
							<Switch checked={currFormValues?.enabled} />
						</FormItem>
					</FormItemRow>

					{/* Payment settings */}
					<FormLabel size={16}>Payment settings</FormLabel>
					<Description style={{ marginBottom: 16 }}>
						Define the availability of the plan based on time and order value
					</Description>

					<FormLabel noMargin>Plan availability</FormLabel>
					<Description>Define timing option for plan availability</Description>
					<FormItem initialValue="always" name={["availability", "type"]} style={{ maxWidth: 190 }}>
						<RadioGroup
							options={[
								{ label: "Always", value: "always" },
								{ label: "At", value: "at" },
								{ label: "Before", value: "before" }
							]}
						/>
					</FormItem>

					{/* sub fields for "AT" */}
					<Flex
						hidden={currFormValues?.availability?.type !== "at"}
						gap={isMobile ? "0px" : "64px"}
						vertical={isMobile}
					>
						<Flex vertical>
							<FormLabel noMargin required>
								Available from
							</FormLabel>
							<Description>Specify when it becomes available</Description>
							<FormItem
								name={["availability", "atStartDate"]}
								rules={
									currFormValues?.availability?.type === "at"
										? [{ required: true, message: "Date and time are required" }]
										: []
								}
								hidden={currFormValues?.availability?.type !== "at"}
							>
								<DateTimePicker
									showTime
									timezone={eventTimezone}
									maxDate={currFormValues.availability?.atEndDate || undefined}
								/>
							</FormItem>
						</Flex>
						<Flex vertical>
							<FormLabel noMargin required>
								Available to
							</FormLabel>
							<Description>Specify when it is no longer available</Description>

							<FormItem
								name={["availability", "atEndDate"]}
								rules={
									currFormValues?.availability?.type === "at"
										? [
												{
													required: true,
													message: "Date and time are required"
												}
										  ]
										: []
								}
								hidden={currFormValues?.availability?.type !== "at"}
							>
								<DateTimePicker
									showTime
									timezone={eventTimezone}
									minDate={currFormValues.availability?.atStartDate || undefined}
								/>
							</FormItem>
						</Flex>
					</Flex>

					{/* subfields for "BEFORE" */}
					<Flex
						hidden={currFormValues?.availability?.type !== "before"}
						gap={isMobile ? "0px" : "42px"}
						vertical={isMobile}
					>
						<Flex vertical>
							<FormLabel noMargin required>
								Available from
							</FormLabel>
							<Description>Set a custom start time, measured in days or hours before your event begins</Description>
							<Flex gap="8px">
								<FormItem
									name={["availability", "beforeStartDays"]}
									rules={currFormValues?.availability?.type === "before" ? daysRules : []}
									hidden={currFormValues?.availability?.type !== "before"}
								>
									<CurvedInputNumber placeholder="days" suffix="Days" />
								</FormItem>
								<FormItem
									name={["availability", "beforeStartHours"]}
									rules={currFormValues?.availability?.type === "before" ? hoursRules : []}
									hidden={currFormValues?.availability?.type !== "before"}
								>
									<CurvedInputNumber placeholder="hours" suffix="Hours" />
								</FormItem>
							</Flex>
						</Flex>
						<Flex vertical>
							<FormLabel noMargin required>
								Available to
							</FormLabel>
							<Description>Set a custom end time, measured in days or hours before your event begins</Description>
							<Flex gap="8px">
								<FormItem
									name={["availability", "beforeEndDays"]}
									rules={currFormValues?.availability?.type === "before" ? daysRules : []}
								>
									<CurvedInputNumber placeholder="days" suffix="Days" />
								</FormItem>
								<FormItem
									name={["availability", "beforeEndHours"]}
									rules={currFormValues?.availability?.type === "before" ? hoursRules : []}
								>
									<CurvedInputNumber placeholder="hours" suffix="Hours" />
								</FormItem>
							</Flex>
						</Flex>
					</Flex>

					{/* Price thresholds */}
					<Flex vertical style={{ marginTop: 20 }}>
						<FormLabel noMargin>Price thresholds</FormLabel>
						<Description>Set the price point at which the ticket plan becomes available</Description>
						<Flex gap={isMobile ? "0px" : "16px"} vertical={isMobile}>
							<Flex vertical>
								<FormLabel noMargin>Min price</FormLabel>
								<FormItem
									name="minPrice"
									rules={[
										{
											type: "number",
											min: 0,
											message: "Min price must be greater 0"
										},
										...(currFormValues.maxPrice && currFormValues.maxPrice > 0
											? [
													{
														type: "number",
														max: currFormValues.maxPrice,
														message: "Min price must be less than the max price"
													} as Rule
											  ]
											: [])
									]}
								>
									<CurvedInputNumber
										prefix={eventCurrencySymbol}
										placeholder="Select min price"
										style={{ width: isMobile ? "100%" : "auto" }}
									/>
								</FormItem>
							</Flex>
							<Flex vertical>
								<FormLabel noMargin>Max price</FormLabel>
								<FormItem
									name="maxPrice"
									rules={[
										{
											type: "number",
											min: 0,
											message: "Max price must be greater 0"
										},
										...(currFormValues.minPrice && currFormValues.minPrice > 0
											? [
													{
														type: "number",
														min: currFormValues.minPrice,
														message: "Max price must be greater than the min price"
													} as Rule
											  ]
											: [])
									]}
								>
									<CurvedInputNumber
										prefix={eventCurrencySymbol}
										placeholder="Select max price"
										style={{ width: isMobile ? "100%" : "auto" }}
									/>
								</FormItem>
							</Flex>
						</Flex>
					</Flex>

					<hr style={{ margin: "16px 0px 32px 0px" }} />

					{/* Payment terms */}
					<FormLabel noMargin size={16}>
						Payment terms
					</FormLabel>
					<Description style={{ marginBottom: 16 }}>
						Choose the initial payment amount, the payment frequency, cancellation terms and due dates
					</Description>
					<Flex vertical={isMobile} gap={isMobile ? "0px" : "64px"}>
						{/* Surcharge */}
						<Flex vertical>
							<FormLabel noMargin required>
								Surcharge
							</FormLabel>
							<Description>Set surcharge for entering a payment plan. Evenly split across all instalments</Description>
							<Flex gap="4px">
								<FormItem
									name={["surcharge", "value"]}
									rules={[
										{
											type: "number",
											required: true,
											min: 0,
											message: "A surcharge is required"
										},
										...(String(currFormValues?.surcharge?.unit) === "percent"
											? [
													{
														type: "number",
														min: 0,
														max: 100,
														message: "Max surcharge is 100%"
													} as Rule
											  ]
											: [])
									]}
								>
									<CurvedInputNumber />
								</FormItem>
								<FormItem name={["surcharge", "unit"]} rules={[{ required: true }]} initialValue="flat">
									<RadioGroup
										options={[
											{ label: "%", value: "percent" },
											{ label: eventCurrencySymbol, value: "flat" }
										]}
									/>
								</FormItem>
							</Flex>
						</Flex>
						{/* Deposit */}
						<Flex vertical>
							<FormLabel noMargin required>
								Deposit
							</FormLabel>
							<Description>
								Set a <b>refundable</b> amount charged as part of the first payment
							</Description>
							<Flex gap="4px">
								<FormItem
									name={["deposit", "value"]}
									rules={[
										{
											type: "number",
											required: true,
											min: 0,
											message: "A deposit is required"
										},
										...(String(currFormValues?.deposit?.unit) === "percent"
											? [
													{
														type: "number",
														min: 0,
														max: 100,
														message: "Max deposit is 100%"
													} as Rule
											  ]
											: [])
									]}
								>
									<CurvedInputNumber />
								</FormItem>
								<FormItem initialValue="flat" name={["deposit", "unit"]} rules={[{ required: true }]}>
									<RadioGroup
										options={[
											{ label: "%", value: "percent" },
											{ label: eventCurrencySymbol, value: "flat" }
										]}
									/>
								</FormItem>
							</Flex>
						</Flex>
						{/* Cancellation fee */}
						<Flex vertical>
							<FormLabel noMargin required>
								Cancellation fee
							</FormLabel>
							<Description>
								Set a <b>non-refundable</b> amount charged as part of the first payment
							</Description>
							<Flex gap="4px">
								<FormItem
									name={["cancellationFee", "value"]}
									rules={[
										{
											type: "number",
											required: true,
											min: 0,
											message: "A cancellation fee is required"
										},
										...(String(currFormValues?.cancellationFee?.unit) === "percent"
											? [
													{
														type: "number",
														min: 0,
														max: 100,
														message: "Max cancellation fee is 100%"
													} as Rule
											  ]
											: [])
									]}
								>
									<CurvedInputNumber />
								</FormItem>
								<FormItem name={["cancellationFee", "unit"]} rules={[{ required: true }]} initialValue="flat">
									<RadioGroup
										options={[
											{ label: "%", value: "percent" },
											{ label: eventCurrencySymbol, value: "flat" }
										]}
									/>
								</FormItem>
							</Flex>
						</Flex>
					</Flex>
					<br />
					<Flex vertical={isMobile} gap={isMobile ? "0px" : "64px"}>
						<Flex vertical>
							<FormLabel noMargin required>
								Frequency
							</FormLabel>
							<Description>Set the payment frequency</Description>
							<FormItem name="frequency" rules={[{ required: true, message: "A frequency is required" }]}>
								<Select
									style={{ minWidth: 180 }}
									options={[
										{ value: "monthly", label: "Monthly" },
										{ value: "weekly", label: "Weekly" }
									]}
								/>
							</FormItem>
						</Flex>

						<Flex vertical>
							<FormLabel noMargin required tooltip="Minimum number of occurrences is 2">
								Min occurrence
							</FormLabel>
							<Description>Set the minimum number of occurrences</Description>
							<FormItem
								name="minOccurrence"
								rules={[
									{ required: true, type: "number", message: "A minimum occurrence is required" },
									{
										min: 2,
										type: "number",
										message: "Minimum occurrence must be at least 2"
									}
								]}
							>
								<CurvedInputNumber style={{ width: isMobile ? "100%" : "auto" }} />
							</FormItem>
						</Flex>
						<Flex vertical>
							<FormLabel noMargin required>
								Max occurrence
							</FormLabel>
							<Description>Set the maximum number of occurrences</Description>
							<FormItem
								name="maxOccurrence"
								rules={[
									{
										required: true,
										type: "number",
										message: "A maximum occurrence is required"
									},
									{
										min: currFormValues.minOccurrence,
										type: "number",
										message: "Max occurrence must be greater than or equal to min occurrence"
									}
								]}
							>
								<CurvedInputNumber style={{ width: isMobile ? "100%" : "auto" }} />
							</FormItem>
						</Flex>
					</Flex>

					<FormLabel noMargin required>
						Due date
					</FormLabel>
					<Description>Set when the final payment is due</Description>
					<FormItem initialValue="at" name={["dueDate", "type"]} style={{ maxWidth: 190 }} rules={[{ required: true }]}>
						<RadioGroup
							options={[
								{ label: "At", value: "at" },
								{ label: "Before", value: "before" }
							]}
						/>
					</FormItem>

					<Flex hidden={currFormValues?.dueDate?.type !== "at"}>
						<Flex vertical>
							<FormItem
								name={["dueDate", "atDate"]}
								rules={
									currFormValues?.dueDate?.type === "at"
										? [{ required: true, message: "Date and time are required" }]
										: []
								}
							>
								<DateTimePicker showTime timezone={eventTimezone} />
							</FormItem>
						</Flex>
					</Flex>

					<Flex hidden={currFormValues?.dueDate?.type !== "before"} gap="42px">
						<Flex vertical>
							<Flex vertical={isMobile} gap={isMobile ? "0px" : "8px"}>
								<FormItem
									required
									name={["dueDate", "beforeDays"]}
									rules={
										currFormValues?.dueDate?.type === "before"
											? [
													{
														required: true,
														message: "Days is required",
														type: "number"
													},
													{
														pattern: /^[0-9]*$/,
														message: "Days must be a whole number"
													}
											  ]
											: []
									}
								>
									<CurvedInputNumber placeholder="days" suffix="Days" />
								</FormItem>
								<FormItem
									name={["dueDate", "beforeHours"]}
									rules={
										currFormValues?.dueDate?.type === "before"
											? [
													{
														required: true,
														message: "Hours is required"
													},
													{
														pattern: /^[0-9]*$/,
														message: "Hours must be a whole number"
													},
													{
														type: "number",
														max: 24,
														message: "Hours should be less than 24."
													}
											  ]
											: []
									}
								>
									<CurvedInputNumber placeholder="hours" suffix="Hours" />
								</FormItem>
							</Flex>
						</Flex>
					</Flex>
				</Drawer>
			)}
		</Form>
	);
};
