import { Modal } from "@/ui/antd";
import { Field } from "redux-form";
import { RadioField, SelectField } from "@/components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";

function OwnershipModal({ createFor, isOpen, closeFunction, usersToCreateFor }) {
	return (
		<Modal
			title="Event Ownership"
			open={isOpen}
			maskClosable={false}
			closable={false}
			footer={[
				<LegacyButton
					style={{ float: "right" }}
					ariaLabel="Continue"
					key="submit"
					type="secondary"
					onClick={closeFunction}
				>
					Continue
				</LegacyButton>
			]}
		>
			<Field
				label="Who are you creating this event for"
				name="createFor"
				component={RadioField}
				vertical
				defaultValue="myself"
				options={[
					{
						label: "For myself",
						value: "myself"
					},
					{
						label: "For a shared account",
						value: "shared"
					}
				]}
				size="default"
			/>
			{createFor === "shared" ? (
				<Field name="sharedUserId" label="Select Account" component={SelectField} options={usersToCreateFor} />
			) : null}
		</Modal>
	);
}

export default OwnershipModal;
