import { Field } from "redux-form";
import { RadioField, SwitchField } from "../components/Fields";

/**
 * Tour settings
 *
 * @param {*} props
 * @returns
 */
const TourSettings = (props) => {
	const { pageLayout, showName } = props;

	return (
		<div>
			<Field
				name="settings.showName"
				label="Display Tour Name"
				description="If your banner image has a tour name inside, you may consider disabling extra tour name display"
				component={SwitchField}
			/>
			<hr style={{ borderBottom: "1px solid #dedede" }} />
			{showName ? (
				<Field
					name="settings.namePlacement"
					component={RadioField}
					vertical
					defaultValue="below"
					options={[
						{
							label: "Display name below the banner image",
							value: "below"
						},
						{
							label: "Display name on the banner image",
							value: "inside"
						}
					]}
					size="default"
				/>
			) : null}
			<Field
				label="Tour Page Layout"
				description="Select how would you like your events to be displayed on Tour page"
				name="settings.pageLayout"
				component={RadioField}
				defaultValue="tiles"
				button
				options={[
					{
						label: "Tiles view",
						value: "tiles"
					},
					{
						label: "List view",
						value: "list"
					}
				]}
				size="default"
			/>
			{pageLayout === "list" ? (
				<Field
					name="settings.listViewType"
					component={RadioField}
					defaultValue="image"
					vertical
					options={[
						{
							label: "Image list",
							value: "image"
						},
						{
							label: "Date list",
							value: "date"
						}
					]}
					size="default"
				/>
			) : null}
			<Field
				label="Event Listing Layout"
				description="Select which info will be shown as a primary info header in the event box"
				name="settings.eventListingLayout"
				component={RadioField}
				vertical
				defaultValue="eventTitle"
				options={[
					{
						label: "Event title",
						value: "eventTitle"
					},
					{
						label: "Location",
						value: "location"
					}
				]}
				size="default"
			/>
			<hr style={{ borderBottom: "1px solid #dedede" }} />
			<Field name="settings.showPrice" label="Show Price on Event Listing Pages" component={SwitchField} />
		</div>
	);
};

export default TourSettings;
