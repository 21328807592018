import { Field } from "redux-form";
import { LegacyInputField } from "@/components/Fields";

const SubFields = ({ children = [] }) => {
	const isMobileScreen = window.innerWidth <= 600;
	if (!children.length) {
		return null;
	}

	return (
		<div style={{ maxWidth: 265 }}>
			{children.map((child) => (
				<Field
					key={child.name}
					name={child.name}
					label={child.label}
					placeholder={child.value}
					component={LegacyInputField}
					style={{ margin: 0 }}
					required={child.required}
					size={isMobileScreen ? "large" : "default"}
				/>
			))}
		</div>
	);
};

export default SubFields;
