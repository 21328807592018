import { css } from "@emotion/css";
import { Radio, Tooltip } from "@/ui/antd";
import InputWrapper from "./helpers/InputWrapper";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const RadioField = ({
	style = {},
	input,
	label,
	description,
	inline,
	required,
	defaultValue,
	options,
	square,
	size = "default",
	inputDescriptionStyle,
	button,
	vertical,
	tooltip,
	tooltipIcon = false,
	tooltipIconOnClick,
	meta: { touched, error },
	bold,
	border = false,
	disabled
}) => {
	const Option = button ? RadioButton : Radio;

	const wrapper = css({
		...style
	});

	const radioStyle = vertical
		? {
				display: "block",
				height: "30px",
				lineHeight: "30px"
		  }
		: {};

	const squareStyle = square
		? {
				height: 130,
				width: 130,
				marginBottom: 16
		  }
		: {
				minWidth: 202,
				height: "auto",
				marginBottom: 8,
				width: 202
		  };

	const content = square
		? css({
				display: "flex",
				flexDirection: "column",
				wordWrap: "break-word",
				whiteSpace: "normal",
				textAlign: "center",
				marginTop: -15,
				img: {
					marginBottom: 10
				}
		  })
		: css({
				img: {
					paddingRight: 8
				}
		  });

	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			id={input.name}
			inline={inline}
			required={required}
			inputDescriptionStyle={inputDescriptionStyle}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
			bold={bold}
		>
			<div className={wrapper}>
				<RadioGroup
					id={input.name?.toLowerCase().split(".").join("-")}
					{...input}
					defaultValue={defaultValue}
					size={size}
					buttonStyle="solid"
					disabled={disabled}
					onBlur={() => null}
				>
					{options.map((option) => {
						return (
							<Tooltip title={option.tooltip} key={`tooltip_${option.value}`}>
								<Option
									style={
										border
											? {
													...radioStyle,
													...squareStyle,
													border: input.value === option.value ? "solid 1px #353337" : "solid 1px #dedede",
													borderRadius: 4,
													padding: 6
											  }
											: { ...radioStyle }
									}
									key={option.value}
									value={option.value}
									id={`${input.name}-${option.value}`}
									data-cy={`${input.name}-${option.value}`}
									disabled={option.disabled}
								>
									<span className={content}>
										{option.icon ? <img src={option.icon} alt={option.value} /> : null}
										{option.label}
									</span>
								</Option>
							</Tooltip>
						);
					})}
				</RadioGroup>
			</div>
		</InputWrapper>
	);
};

export default RadioField;
