import { notification } from "@/ui/antd";
import EventsService from "../../services/EventsService";
import MerchandiseService from "../../services/MerchandiseService";
import { GET_USER_EVENTS_ERROR, GET_USER_EVENTS_REQUEST, GET_USER_EVENTS_SUCCESS } from "./eventActions";
import { getLocationFromState } from "./locationActions";

export const GET_MERCH_REQUEST = "GET_MERCH_REQUEST";
export const GET_MERCH_SUCCESS = "GET_MERCH_SUCCESS";
export const GET_MERCH_ERROR = "GET_MERCH_ERROR";

export const SAVE_MERCH_REQUEST = "SAVE_MERCH_REQUEST";
export const SAVE_MERCH_SUCCESS = "SAVE_MERCH_SUCCESS";
export const SAVE_MERCH_ERROR = "SAVE_MERCH_ERROR";

export const GET_MERCH_LIST_REQUEST = "GET_MERCH_LIST_REQUEST";
export const GET_MERCH_LIST_SUCCESS = "GET_MERCH_LIST_SUCCESS";
export const GET_MERCH_LIST_ERROR = "GET_MERCH_LIST_ERROR";

export const GET_MERCH_REPORT_REQUEST = "GET_MERCH_REPORT_REQUEST";
export const GET_MERCH_REPORT_SUCCESS = "GET_MERCH_REPORT_SUCCESS";
export const GET_MERCH_REPORT_ERROR = "GET_MERCH_REPORT_ERROR";

export const GET_MERCH_REPORT_CSV_REQUEST = "GET_MERCH_REPORT_CSV_REQUEST";
export const GET_MERCH_REPORT_CSV_SUCCESS = "GET_MERCH_REPORT_CSV_SUCCESS";
export const GET_MERCH_REPORT_CSV_ERROR = "GET_MERCH_REPORT_CSV_ERROR";

export const GET_EVENT_TICKET_TYPES_REQUEST = "GET_EVENT_TICKET_TYPES_REQUEST";
export const GET_EVENT_TICKET_TYPES_ERROR = "GET_EVENT_TICKET_TYPES_ERROR";
export const GET_EVENT_TICKET_TYPES_SUCCESS = "GET_EVENT_TICKET_TYPES_SUCCESS";

export const MOVE_MERCH_IN_LIST = "MOVE_MERCH_IN_LIST";

export const getEventTicketTypes = (userId, eventIds = [], location) => {
	return (dispatch) => {
		dispatch({ type: GET_EVENT_TICKET_TYPES_REQUEST });
		EventsService.getUserTicketTypes(userId, eventIds, location)
			.then((ticketTypes) => {
				dispatch({
					type: GET_EVENT_TICKET_TYPES_SUCCESS,
					ticketTypes
				});
			})
			.catch((err) => {
				dispatch({ type: GET_EVENT_TICKET_TYPES_ERROR, error: err });
			});
	};
};

export const getUserEvents = (userId) => {
	return (dispatch) => {
		dispatch({ type: GET_USER_EVENTS_REQUEST });
		const location = getLocationFromState(true);
		EventsService.getUserEvents(userId, "name _id settings", true, location)
			.then((userEvents) => {
				dispatch({ type: GET_USER_EVENTS_SUCCESS, events: userEvents });
			})
			.catch((err) => {
				dispatch({ type: GET_USER_EVENTS_ERROR, error: err });
			});
	};
};

export const getMerchList = (userId) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: GET_MERCH_LIST_REQUEST });
		try {
			MerchandiseService.find(userId, location).then((merchList) => {
				dispatch({ type: GET_MERCH_LIST_SUCCESS, merchList });
			});
		} catch (err) {
			dispatch({ type: GET_MERCH_LIST_ERROR, err });
		}
	};
};

export const getMerch = (userId, merchId, navigate) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: GET_MERCH_REQUEST });
		try {
			MerchandiseService.get(userId, merchId, location).then((merch) => {
				dispatch({ type: GET_MERCH_SUCCESS, merchData: merch });
				if (navigate) {
					navigate(`/console/promote/add-ons/${merchId}/update`);
				}
			});
		} catch (err) {
			dispatch({ type: GET_MERCH_ERROR, err });
		}
	};
};

export const updateMerch = (userId, merchId, merchData, navigate) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: SAVE_MERCH_REQUEST });
		try {
			MerchandiseService.update(userId, merchId, merchData, location).then((merchList) => {
				dispatch({ type: SAVE_MERCH_SUCCESS, merchList });
				if (navigate) {
					navigate(`/console/promote/add-ons`);
				}
			});
		} catch (err) {
			dispatch({ type: SAVE_MERCH_ERROR, err });
		}
	};
};

export const createMerch = (userId, merchData, navigate) => {
	return (dispatch) => {
		dispatch({ type: SAVE_MERCH_REQUEST });
		try {
			MerchandiseService.create(userId, merchData).then((merchList) => {
				dispatch({ type: SAVE_MERCH_SUCCESS, merchList });
				if (navigate) {
					navigate(`/console/promote/add-ons`);
				}
			});
		} catch (err) {
			dispatch({ type: SAVE_MERCH_ERROR, err });
		}
	};
};

export const moveMerchInList = (fromId, toId) => {
	return (dispatch) => {
		dispatch({ type: MOVE_MERCH_IN_LIST, fromId, toId });
	};
};

export const saveMerchOrder = () => {
	return async (_, getState) => {
		const state = getState();
		const merchList = state.merchandise.merchList;
		const merchOrder = merchList.map((merch, index) => ({ _id: merch._id, position: index }));
		try {
			await MerchandiseService.saveMerchOrder(state.auth.user.id, merchOrder);
			notification.success({ message: "Add-on order saved" });
		} catch (err) {
			notification.error({ message: "Please try again later" });
		}
	};
};
