import { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { ContentBlock } from "../components/AppBlocks";
import { LegacyInputField } from "../components/Fields";
import FormBar from "../components/FormBar";
import LoadErrorView from "../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1 } from "../components/text/Text";
import withSaveCheck from "../hoc/withSaveCheck";
import { update } from "../state/actions/authActions";
import { required, mobile } from "../utils/Validators";

const firstNameRequired = required("Please input your first name");
const lastNameRequired = required("Please input your last name");
const mobileRequired = required("Please input your mobile");
const validMobile = mobile("Please enter a valid mobile");

class ProfileForm extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
	}

	save(values) {
		const { update } = this.props;
		update(values);
	}

	render() {
		const { handleSubmit, auth } = this.props;
		const loading = auth.loading;
		return (
			<LoadErrorView loading={loading}>
				<form onSubmit={handleSubmit(this.save)}>
					<H1>Profile</H1>
					<ContentBlock>
						<Field name="email" label="Email Address" placeholder="Email" component={LegacyInputField} disabled />
						<Field
							name="firstName"
							label="First Name"
							placeholder="First Name"
							component={LegacyInputField}
							required
							validate={firstNameRequired}
						/>
						<Field
							name="lastName"
							label="Last Name"
							placeholder="Last Name"
							component={LegacyInputField}
							required
							validate={lastNameRequired}
						/>
						<Field
							name="mobile"
							label="Mobile"
							placeholder="Mobile"
							component={LegacyInputField}
							required
							validate={[mobileRequired, validMobile]}
						/>
						<Field name="organization" label="Organisation" placeholder="Organisation" component={LegacyInputField} />
					</ContentBlock>

					<FormBar>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							ariaLabel="Update"
							size="large"
						>
							Update
						</LegacyButton>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

const formName = "userProfile";

// Decorate with redux-form
ProfileForm = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(ProfileForm);

export default connect(
	(state) => {
		return {
			auth: state.auth,
			initialValues: {
				firstName: state.auth.user.firstName,
				lastName: state.auth.user.lastName,
				mobile: state.auth.user.mobile,
				email: state.auth.user.email,
				organization: state.auth.user.organization
			}
		};
	},
	{ update }
)(withSaveCheck(ProfileForm, formName));
