import AuthService from "@/services/AuthService";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { css } from "@emotion/css";
import { Alert, Modal } from "@/ui/antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import type { IWithViewPortProps } from "@/hoc/withViewport";
import withViewport from "@/hoc/withViewport";
import { closeNotifyFollowersModal, handleNotifyFollowers } from "@/state/actions/eventActions";
import { getOrganiser } from "@/state/actions/organiserActions";
import type { ReduxState as GlobalReduxState } from "@/state/store";

const buttonBar = css({
	display: "flex",
	justifyContent: "center"
});

interface ReduxActions {
	closeNotifyFollowersModal: () => void;
	handleNotifyFollowers: (eventId: string | undefined) => void;
	getOrganiser: (organiserId: string | undefined) => void;
}

const pickDataFromState = (state: Partial<GlobalReduxState>) => ({
	currentEvent: state.currentEvent,
	followerCount: state.organisers?.selected?.followerCount,
	isVerified: AuthService.isVerified()
});

type LocalisedReduxState = ReturnType<typeof pickDataFromState>;

type ReduxInput = LocalisedReduxState & ReduxActions;

type NotifyFollowersModalInput = IWithViewPortProps & ReduxInput;

const NotifyFollowersModal = ({
	getOrganiser,
	followerCount,
	currentEvent,
	closeNotifyFollowersModal,
	handleNotifyFollowers,
	isMobile,
	isVerified
}: NotifyFollowersModalInput) => {
	const { notifyFollowersState, event } = (currentEvent as any) ?? {};
	const { _id: eventId, name: eventName, published, notifyFollowers: followersNotified, organiserId } = event ?? {};
	const { isModalOpen } = notifyFollowersState ?? {};
	const titleText = "Notify my followers";

	useEffect(() => {
		if (organiserId) {
			getOrganiser(organiserId);
		}
	}, []);

	const plurals =
		followerCount > 1
			? {
					followers: "followers",
					have: "have"
			  }
			: {
					followers: "follower",
					have: "has"
			  };

	const footerBtns = (
		<>
			{published && !followersNotified ? (
				<ButtonsBar footer>
					<LegacyButton key="cancel" onClick={closeNotifyFollowersModal} ariaLabel="Cancel">
						Cancel
					</LegacyButton>
					<LegacyButton
						disabled={!isVerified}
						key="submit"
						ariaLabel={titleText}
						type="primary"
						onClick={() => {
							handleNotifyFollowers(eventId);
						}}
					>
						{titleText}
					</LegacyButton>
				</ButtonsBar>
			) : (
				<div className={buttonBar}>
					<LegacyButton key="ok" onClick={closeNotifyFollowersModal} ariaLabel="OK">
						OK
					</LegacyButton>
				</div>
			)}
		</>
	);

	const modalMessage = () => {
		if (!isVerified) {
			return (
				<Alert
					showIcon
					type="info"
					message="Account verification required"
					description={
						<p>
							Please submit an <a onClick={OpenHelpHandler(HelpIds.verifyToPublish)}>account verification request</a> to
							use this feature. Once verified, you can send the Follower notification
						</p>
					}
					style={{ marginBottom: 8 }}
				/>
			);
		}
		if (!published) {
			return (
				<p>
					The event <b>{eventName}</b> is a draft.
					<br />
					<br />
					Publish the event first to notify your {followerCount} {plurals.followers}.
				</p>
			);
		}

		if (followersNotified) {
			return (
				<p>
					Your {plurals.followers} {plurals.have} already been notified and cannot be notified again.
				</p>
			);
		}

		return (
			<>
				<p>
					Are you sure you want to notify your {followerCount} {plurals.followers} about this event?
					<br />
				</p>
				<Alert
					type="info"
					showIcon
					style={{ marginBottom: 16 }}
					description={
						<ul style={{ marginBottom: 0 }}>
							<li>This will send an email immediately.</li>
							<li>This feature can only be executed once per event.</li>
						</ul>
					}
				/>
			</>
		);
	};

	return (
		<Modal
			open={isModalOpen}
			title={titleText}
			onCancel={closeNotifyFollowersModal}
			destroyOnClose
			footer={footerBtns}
			style={isMobile ? { top: 0 } : undefined}
		>
			<div>{modalMessage()}</div>
		</Modal>
	);
};

export default withViewport(
	connect(pickDataFromState, {
		closeNotifyFollowersModal,
		handleNotifyFollowers,
		getOrganiser
	})(NotifyFollowersModal)
);
