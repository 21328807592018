import { Modal, notification } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { ImageUploadField, LegacyInputField, SelectField, TextAreaField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "@/components/text/Text";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { required } from "@/utils/Validators";
import { withRouterHooks } from "@/hoc/withRouterHooks";

import {
	clearAccounts,
	clearPostErrors,
	createEvent,
	deleteEvent,
	disconnectFacebook,
	loadAccounts,
	updateEvent
} from "@/state/actions/facebookActions";

const { confirm } = Modal;

const categoryOptions = [
	{ label: "Art Event", value: "ART_EVENT" },
	{ label: "Book Event", value: "BOOK_EVENT" },
	{ label: "Movie", value: "MOVIE_EVENT" },
	{ label: "Fundraiser", value: "FUNDRAISER" },
	{ label: "Volunteering", value: "VOLUNTEERING" },
	{ label: "Family", value: "FAMILY_EVENT" },
	{ label: "Festival", value: "FESTIVAL_EVENT" },
	{ label: "Neighborhood", value: "NEIGHBORHOOD" },
	{ label: "Religious", value: "RELIGIOUS_EVENT" },
	{ label: "Shopping", value: "SHOPPING" },
	{ label: "Comedy", value: "COMEDY_EVENT" },
	{ label: "Music", value: "MUSIC_EVENT" },
	{ label: "Dance", value: "DANCE_EVENT" },
	{ label: "Nightlife", value: "NIGHTLIFE" },
	{ label: "Theater", value: "THEATER_EVENT" },
	{ label: "Dining", value: "DINING_EVENT" },
	{ label: "Food", value: "FOOD_TASTING" },
	{ label: "Conference", value: "CONFERENCE_EVENT" },
	{ label: "Meetup", value: "MEETUP" },
	{ label: "Class Event", value: "CLASS_EVENT" },
	{ label: "Lecture", value: "LECTURE" },
	{ label: "Workshop", value: "WORKSHOP" },
	{ label: "Fitness", value: "FITNESS" },
	{ label: "Sports", value: "SPORTS_EVENT" },
	{ label: "Other", value: "OTHER" }
];

class PostToFacebook extends Component {
	componentDidMount() {
		const { loadAccounts, user, currentEvent, params } = this.props;
		const facebookId = params?.facebookId ? params?.facebookId : null;
		const facebookEvent = facebookId
			? currentEvent.event.facebookEvents.find((fbEvent) => String(fbEvent.facebookId) === String(facebookId))
			: false;
		loadAccounts(user.id, facebookEvent);
	}

	componentWillUnmount() {
		this.props.clearAccounts();
	}

	save = (facebookEvent) => {
		const { currentEvent, createEvent, updateEvent, params, navigate } = this.props;
		const event = currentEvent.event;
		const facebookId = params?.facebookId ? params?.facebookId : null;

		if (!event.description) {
			notification.error({
				message: "Error",
				description: "Facebook events require a description"
			});
			return;
		}

		if (!event.bannerImage && !facebookEvent.cover) {
			notification.error({
				message: "Error",
				description: "Facebook events require a cover image"
			});
			return;
		}

		if (facebookId) {
			updateEvent(facebookEvent, event._id);
		} else {
			createEvent(facebookEvent, event._id, navigate);
		}
	};

	deleteEvent = () => {
		const { deleteEvent, currentEvent, params, navigate } = this.props;
		const facebookId = params?.facebookId ? params?.facebookId : null;
		const facebookEvent = currentEvent.event.facebookEvents.find((fbEvent) => fbEvent.facebookId === facebookId);
		confirm({
			title: "Are you sure you would like to delete this event from Facebook?",
			onOk: () => deleteEvent(facebookEvent, currentEvent.event._id, navigate),
			onCancel() {
				null;
			}
		});
	};

	disconnectAccount = () => {
		const { user, disconnectFacebook } = this.props;
		confirm({
			title: "Are you sure you would like to disconnect your account from Facebook?",
			onOk: () => disconnectFacebook(user.id),
			onCancel() {
				null;
			}
		});
	};

	nameRequired = required("A name is required");
	pageRequired = required("A page is required");
	descriptionRequired = required("A description is required");
	imageRequired = required("An image is required");
	categoryRequired = required("A category is required");

	render() {
		const { user, facebook, handleSubmit, params } = this.props;
		const facebookId = params?.facebookId ? params?.facebookId : null;
		const eventCreated = facebookId;

		const pageOptions = facebook.account.pages
			? facebook.account.pages.map((p) => {
					return { label: p.name, value: p.id };
			  })
			: [];

		const loading =
			facebook.account.loading ||
			facebook.post.loading ||
			facebook.delete.loading ||
			facebook.disconnect.loading ||
			(facebookId && facebook.event.loading);

		return (
			<form onSubmit={handleSubmit(this.save)}>
				<ContentBlock>
					<LoadErrorView loading={loading}>
						<div>
							{facebookId ? (
								<a href={`https://www.facebook.com/events/${facebookId}`} target="_blank" rel="noopener noreferrer">
									<LegacyButton
										style={{
											float: "right"
										}}
										ariaLabel="View Event"
										size="large"
									>
										View Event
									</LegacyButton>
								</a>
							) : null}
							<div
								style={{
									borderBottom: "1px solid rgba(32, 7, 64, 0.13)",
									padding: "0 0 16px 0",
									marginBottom: 16
								}}
							>
								<P style={{ lineHeight: "22px" }}>
									<b>Connected to Facebook as:</b>
									<br />
									{user.facebookSettings.connection.name}
								</P>
								{/*eslint-disable-next-line*/}
								<a
									aria-label="Disconnect from facebook"
									onClick={() => this.disconnectAccount()}
									style={{ textDecoration: "underline" }}
								>
									Disconnect
								</a>
							</div>
							<div style={{ maxWidth: 400 }}>
								<Field
									name="pageId"
									label="Facebook page"
									description="Which page would you like your event created under"
									component={SelectField}
									validate={this.pageRequired}
									required
									options={pageOptions}
									disabled={!!facebookId}
								/>
								<Field
									name="name"
									label="Facebook event name"
									autocomplete="nope"
									description="What is your event called?"
									component={LegacyInputField}
									validate={this.nameRequired}
									required
								/>

								<Field
									label="Facebook banner image"
									name="cover"
									component={ImageUploadField}
									uploadLabel="Upload a feature image"
									uploadDescription="Choose image for your event. ( min 1000px by 500px, max 10mb )"
									aspectRatio={2}
									required
									validate={this.imageRequired}
								/>
							</div>
							<Field
								label="Description"
								description="Will appear on your events listing"
								id="description"
								name="description"
								required
								component={TextAreaField}
								validate={this.descriptionRequired}
								rows={5}
								placeholder="This is the best event ever because ..."
							/>
							<Field
								name="category"
								label="Facebook Event Category"
								component={SelectField}
								validate={this.categoryRequired}
								options={categoryOptions}
								required
							/>
						</div>
					</LoadErrorView>
				</ContentBlock>
				<FormBar formLayout="horizontal">
					<LegacyButton
						style={{
							float: "right"
						}}
						type="primary"
						htmlType="submit"
						ariaLabel={eventCreated ? "Update event" : "Create event"}
						size="large"
					>
						{eventCreated ? "Update event" : "Create event"}
					</LegacyButton>
					{eventCreated ? (
						<LegacyButton type="warning" ariaLabel="Remove from facebook" size="large" onClick={this.deleteEvent}>
							Remove from facebook
						</LegacyButton>
					) : null}
				</FormBar>
			</form>
		);
	}
}

const formName = "postToFacebook";

PostToFacebook = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(PostToFacebook);

export default withRouterHooks(
	connect(
		(state, ownProps) => {
			const { params } = ownProps;
			const event = state.currentEvent.event;
			const facebookId = params?.facebookId ? params?.facebookId : null;
			let facebookEvent = facebookId
				? event.facebookEvents.find((fbEvent) => String(fbEvent.facebookId) === String(facebookId))
				: false;
			const fbEventDetails = state.facebook.event.data;
			if (fbEventDetails) {
				facebookEvent = {
					...facebookEvent,
					name: fbEventDetails.name,
					description: fbEventDetails.description
				};
			}

			let description = state.currentEvent.event.description.replace(/<(?:.|\n)*?>/gm, "");
			if (state.currentEvent.event.eventLocation.type === "online" && event.eventLocation.instructions) {
				description += ` ${event.eventLocation.instructions}`;
			}
			if (
				state.currentEvent.event.eventLocation.type === "online" &&
				state.currentEvent.event.eventLocation.onlineUrl
			) {
				description += ` ${event.eventLocation.onlineUrl}`;
			}
			//

			const initialValues = facebookEvent
				? facebookEvent
				: {
						name: state.currentEvent.event.name,
						cover: state.currentEvent.event.bannerImage,
						description
				  };
			return {
				initialValues,
				facebook: state.facebook,
				currentEvent: state.currentEvent,
				user: state.auth.user
			};
		},
		{
			createEvent,
			updateEvent,
			deleteEvent,
			loadAccounts,
			clearPostErrors,
			disconnectFacebook,
			clearAccounts
		}
	)(PostToFacebook)
);
