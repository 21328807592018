import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { DateTimeField, RadioField, SelectField } from "@/components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { closePreviewModal, previewVirtualEventHub } from "@/state/actions/virtualEventHubActions";
import { required } from "@/utils/Validators";

const ticketTypeRequired = required("A ticket type is required");
const eventDateRequired = required("An event date is required");
const previewDateRequired = required("A preview date is required");

class PreviewModal extends Component {
	close = () => {
		const { closePreviewModal } = this.props;
		closePreviewModal();
	};

	save = async (values) => {
		const { currentEvent, previewVirtualEventHub } = this.props;
		const eventId = currentEvent.event._id;
		previewVirtualEventHub(
			eventId,
			{
				eventId,
				...values
			},
			currentEvent.event.location
		);
	};

	render() {
		const { virtualEventHub, previewType, handleSubmit, currentEvent } = this.props;
		let tickets = currentEvent.event.ticketTypes
			.filter((t) => !t.isDonation && !t.deleted)
			.concat(currentEvent.event.packagedTickets.filter((t) => !t.deleted));
		tickets = tickets.filter((ticket) => !(ticket.disabled || ticket.deleted));
		const ticketOptions = [].concat(
			tickets.map((ticket) => {
				return { value: ticket._id, label: ticket.name };
			})
		);
		const dateOptions = [].concat(
			currentEvent.event.dates
				.filter((date) => !date.disabled && !date.deleted)
				.map((date) => {
					return {
						value: date._id,
						label: `${date.display.date}, ${date.display.time}`
					};
				})
		);
		return (
			<Modal title="Preview" open={virtualEventHub.previewModalOpen} onCancel={this.close} footer={null}>
				<form onSubmit={handleSubmit(this.save)}>
					<Field
						label="Preview type"
						name="previewType"
						component={RadioField}
						defaultValue="now"
						button
						options={[
							{
								label: "All content",
								value: "all"
							},
							{
								label: "Filtered content",
								value: "filtered"
							}
						]}
						size="default"
					/>
					{previewType !== "all" ? (
						<>
							<Field
								label="Ticket Type"
								description="Preview as if you are a holder of this ticket"
								name="ticketTypeId"
								placeholder="Please select"
								required
								component={SelectField}
								options={ticketOptions}
								validate={ticketTypeRequired}
							/>
							<Field
								label="Event Date"
								description="Preview as if you are attending this event date"
								name="eventDateId"
								placeholder="Please select"
								required
								component={SelectField}
								options={dateOptions}
								validate={eventDateRequired}
							/>
							<Field
								label="Date of preview"
								description="At what time would you like to simulate your preview"
								name="dateOfPreview"
								placeholder="Please select"
								required
								component={DateTimeField}
								validate={previewDateRequired}
								formName={formName}
							/>
						</>
					) : null}
					<div
						style={{
							margin: "0 -24px -12px",
							padding: "12px 24px 0",
							borderTop: "1px solid #e8e8e8"
						}}
					>
						<ButtonsBar footer>
							<LegacyButton
								style={{
									float: "left"
								}}
								key="cancel"
								onClick={this.close}
								htmlType="button"
								ariaLabel="Cancel"
							>
								Cancel
							</LegacyButton>
							<LegacyButton key="preview" type="primary" htmlType="submit" ariaLabel="Preview">
								Preview
							</LegacyButton>
						</ButtonsBar>
					</div>
				</form>
			</Modal>
		);
	}
}

const formName = "hubPreviewModal";
const selector = formValueSelector(formName);
// Decorate with redux-form
PreviewModal = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(PreviewModal);

export default connect(
	(state) => ({
		initialValues: {
			previewType: "all"
		},
		currentEvent: state.currentEvent,
		virtualEventHub: state.virtualEventHub,
		previewType: selector(state, "previewType")
	}),
	{ previewVirtualEventHub, closePreviewModal }
)(PreviewModal);
