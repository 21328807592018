import { Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ContentBlock } from "@/components/AppBlocks";
import HeadingWithSubheading from "@/components/HeadingWithSubheading";
import LoadErrorView from "@/components/LoadErrorView";
import SearchRefinements from "@/components/SearchRefinements";
import SimplePagination from "@/components/SimplePagination";
import { open } from "@/state/actions/dropdownActions";
import { getReport, getReportCsv } from "@/state/actions/globalReportActions";
import { sendOrder } from "@/state/actions/orderActions";
import { updateSearch } from "@/state/actions/searchActions";
import InvoiceStatus from "./InvoiceStatus";
import OrderActions from "./OrderActions";
import { ResendOrderModal } from "./ResendOrderModal";
const CUSTOM_TAX_KEY = "customTax";

class Invoices extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
	}

	load(page = 1) {
		const { getReport } = this.props;
		getReport("invoice", page);
	}

	loadPage(page) {
		const { updateSearch } = this.props;
		updateSearch({ page }, true);
	}

	downloadCsv() {
		const { getReportCsv } = this.props;
		getReportCsv("invoice");
	}

	getFilterOptions() {
		const {
			currentEvent: {
				event: { dates, datesUseCalendar }
			}
		} = this.props;

		return [
			{
				key: "eventDateIds",
				label: "Event Dates",
				mode: "multiple",
				kind: datesUseCalendar ? "eventDatePicker" : "select",
				placeholder: "All dates",
				options: dates.map((date) => ({
					...(datesUseCalendar ? date : {}),
					value: date._id,
					name: `${date.display.date}, ${date.display.time}`
				}))
			},
			{
				key: "financialStatus",
				label: "Invoice status",
				kind: "radio",
				options: [
					{ value: "all", name: "All" },
					{ value: "requiresPayment", name: "Requires Payment" },
					{ value: "paid", name: "Paid" }
				]
			}
		];
	}

	render() {
		const { currentEvent, history, userRole } = this.props;
		const { results, loading, page, csvLoading, error } = this.props.reportState;
		const messages = loading ? { emptyText: "Loading" } : { emptyText: "No Orders" };
		let columns = [
			{
				title: "Order ID",
				dataIndex: "orderName",
				width: 100,
				key: "orderName",
				render: (orderName, record) => {
					const link = record.userId
						? `/console/promote/gift-cards/order/${record._id}`
						: `/console/my-events/${record.eventId}/orders/${record._id}`;
					return <Link to={link}>{orderName}</Link>;
				}
			},
			{
				title: "Invoice Status",
				dataIndex: "invoicePaid",
				key: "invoicePaid",
				width: 180,
				render: (_, record) => <InvoiceStatus order={record} />
			},
			{
				title: "Event Date",
				dataIndex: "date",
				key: "date"
			},
			{
				title: "First Name",
				dataIndex: "firstName",
				key: "firstName"
			},
			{
				title: "Last Name",
				dataIndex: "lastName",
				key: "lastName"
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email"
			},
			{
				title: "Mobile",
				dataIndex: "mobile",
				key: "mobile"
			},
			{
				title: "Order Date & Time",
				dataIndex: "orderDate",
				key: "orderDate"
			},
			{
				title: "Type",
				dataIndex: "orderType",
				key: "orderType"
			},
			{
				title: "Financial status",
				dataIndex: "financialStatus",
				key: "financialStatus"
			},
			{
				title: "Valid tickets",
				dataIndex: "completeTickets",
				key: "completeTickets"
			},
			{
				title: "Cancelled tickets",
				dataIndex: "cancelledTickets",
				key: "cancelledTickets"
			},
			{
				title: "Ticket sales",
				dataIndex: "ticketSales",
				key: "ticketSales"
			},
			{
				title: "Add-on sales",
				dataIndex: "addOnSales",
				key: "addOnSales"
			},
			{
				title: "Donation",
				dataIndex: "additionalDonations",
				key: "additionalDonations"
			},
			{
				title: "Fee rebate",
				dataIndex: "feeRebate",
				key: "feeRebate"
			},
			{
				title: "Humanitix passed-on fees",
				dataIndex: "humanitixFeePassedOn",
				key: "humanitixFeePassedOn"
			},
			{
				title: "Amex surcharge",
				dataIndex: "amexSurcharge",
				key: "amexSurcharge"
			},
			{
				title: "Custom Tax",
				dataIndex: "customTax",
				key: CUSTOM_TAX_KEY
			},
			{
				title: "Paid",
				dataIndex: "paid",
				key: "paid"
			},
			{
				title: "Humanitix absorbed fees",
				dataIndex: "humanitixFeeAbsorbed",
				key: "humanitixFeeAbsorbed"
			},
			{
				title: "Zip fee (absorbed)",
				dataIndex: "zipFee",
				key: "zipFee"
			},
			{
				title: "Afterpay fee (absorbed)",
				dataIndex: "afterpayFee",
				key: "afterpayFee"
			},
			{
				title: "Refunds",
				dataIndex: "refunds",
				key: "refunds"
			},
			{
				title: "Your Earnings",
				dataIndex: "earnings",
				key: "earnings"
			},
			...(userRole === "admin"
				? [
						{
							title: "Your Payout",
							dataIndex: "payout",
							key: "payout"
						}
				  ]
				: []),
			{
				title: "Refunded fees",
				dataIndex: "refundedFees",
				key: "refundedFees"
			},
			{
				title: "Giftcard used",
				dataIndex: "giftcardCredit",
				key: "giftcardCredit"
			},
			{
				title: "Discount code used",
				dataIndex: "discountCode",
				key: "discountCode"
			},
			{
				title: "Discount redeemed",
				dataIndex: "discountValue",
				key: "discountValue"
			},
			{
				title: "Tax on sales",
				dataIndex: "taxOnSales",
				key: "taxOnSales"
			},
			{
				title: "Tax on rebates",
				dataIndex: "taxOnRebates",
				key: "taxOnRebates"
			},
			{
				title: "Tax on booking fees",
				dataIndex: "taxOnBookingFees",
				key: "taxOnBookingFees"
			},
			{
				title: "Gateway",
				dataIndex: "paymentGateway",
				key: "paymentGateway"
			},
			{
				title: "Marketing Opt-in",
				dataIndex: "marketingOptIn",
				key: "marketingOptIn"
			},
			{
				title: "Notes",
				dataIndex: "notes",
				key: "notes"
			},
			{
				title: "Status",
				dataIndex: "orderStatus",
				key: "orderStatus"
			}
		];

		columns = columns.concat([
			{
				title: "Action",
				key: "action",
				fixed: "right",
				width: 100,
				render: (_, record) => {
					return (
						<OrderActions
							eventId={currentEvent.event._id}
							event={currentEvent.event}
							order={record}
							status="status"
							injectEventInfo={false}
							history={history}
						/>
					);
				}
			}
		]);

		const customTaxName = currentEvent?.event?.paymentOptions?.customSalesTaxSettings?.enabled
			? currentEvent.event.paymentOptions.customSalesTaxSettings.name
			: null;

		return (
			<LoadErrorView loading={loading} error={error} retryAction={this.load}>
				<HeadingWithSubheading heading="Paid by invoice" />
				<ContentBlock style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}>
					<SearchRefinements
						defaultSearch={{
							invoice: "true",
							eventIds: [currentEvent.event._id]
						}}
						showRefinements
						filterOptions={this.getFilterOptions()}
						load={this.load}
						dropdownId="eventInvoices"
						download={{
							download: this.downloadCsv,
							loading: csvLoading,
							tooltip: "Export Invoices"
						}}
					/>
					<SimplePagination pageAction={this.loadPage} currentPage={page} />
					<div style={{ minWidth: "100%", overflowX: "auto" }}>
						<Table
							columns={columns.reduce((acc, column) => {
								if (column.key !== CUSTOM_TAX_KEY) {
									return [...acc, column];
								}
								if (currentEvent.event.location === "US" && customTaxName) {
									return [...acc, { ...column, title: customTaxName }];
								}
								return acc;
							}, [])}
							dataSource={results}
							rowKey="_id"
							locale={messages}
							scroll={{ x: "max-content" }}
							pagination={false}
						/>
					</div>
					<SimplePagination pageAction={this.loadPage} currentPage={page} />
					<ResendOrderModal />
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		reportState: state.globalReportState,
		actionsLoading: state.order.actionsLoading,
		userRole: state.auth.user.role
	}),
	{
		updateSearch,
		sendOrder,
		open,
		getReport,
		getReportCsv
	}
)(Invoices);
