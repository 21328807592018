import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector } from "redux-form";
import { RichTextAreaField } from "@/components/Fields";
import PersonalisationInformation from "@/components/PersonalisationInformation";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { closeModal, storeEmailMessage } from "@/state/actions/previewActions";
import { getMessagePersonalisations } from "@/utils/Personalisations";
import TicketMessagesTable from "./TicketMessagesTable";
("@/config");
const personalisationsObject = getMessagePersonalisations();

class EmailMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false
		};
	}
	toggleModel = () => {
		this.setState({ modalOpen: !this.state.modalOpen });
		// const { storeEmailMessage, customEmailMessage, currentEvent } = this.props;
		// storeEmailMessage({ customEmailMessage, eventId: currentEvent.event._id });
	};

	render() {
		// TODO: Steven should take a look at this, someone said it was already fixed
		// const { currentEvent, preview, closeModal, messages, changeFieldValue } = this.props;
		const { currentEvent, messages, changeFieldValue } = this.props;
		// const token = AuthService.token;
		// const fullUrl = `${getConfig("TICKETING_URL")}/api/emails/get-email-preview/${
		// 	currentEvent.event._id
		// }?token=${encodeURIComponent(token)}`;
		return (
			<>
				<LegacyButton
					style={{ float: "right" }}
					icon={{ left: true, name: "preview" }}
					type="action"
					flavor="minimal"
					onClick={this.toggleModel}
					ariaLabel="Preview order email"
				>
					Preview
				</LegacyButton>

				<Field
					label="Add message to confirmation email"
					description="This message will show up on the order confirmation email"
					name="messages.customEmailMessage"
					placeholder="Thank you for purchasing tickets! See you at the Event!"
					component={RichTextAreaField}
					basicEditor={true}
					minHeight={150}
					personalisations={personalisationsObject}
				/>
				<FieldArray
					name="messages.customEmailMessagesByTicketType"
					ticketMessages={messages.customEmailMessagesByTicketType}
					component={TicketMessagesTable}
					currentEvent={currentEvent}
					changeFieldValue={changeFieldValue}
				/>
				<PersonalisationInformation personalisationList={personalisationsObject} />
				<Modal
					title="Email Preview"
					open={this.state.modalOpen}
					onCancel={this.toggleModel}
					destroyOnClose
					footer={
						<LegacyButton ariaLabel="Close" onClick={this.toggleModel}>
							Close
						</LegacyButton>
					}
				>
					{/* JEN-14649 disabled until v2 version is created  */}
					<p>Previews are currently not available. We’re building something new!</p>{" "}
					<p>To preview, we recommend making a free order</p>
					{/* {preview.error ? <H2>Something went wrong :(</H2> : <PreviewIframe title="Preview ticket" url={fullUrl} />} */}
				</Modal>
			</>
		);
	}
}

export default connect(
	(state, ownProps) => {
		const selector = formValueSelector(ownProps.formName);
		return {
			currentEvent: state.currentEvent,
			customEmailMessage: selector(state, "messages.customEmailMessage"),
			preview: state.preview
		};
	},
	{ storeEmailMessage, closeModal }
)(EmailMessage);
