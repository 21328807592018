import { Component } from "react";
import { connect } from "react-redux";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H3 } from "@/components/text/Text";
import { publish } from "@/state/actions/eventActions";
import { loadSDK } from "@/state/actions/facebookActions";
import { HelpIds } from "@/utils/Help";
import ConnectFacebook from "./ConnectFacebook";
import FacebookEventsList from "./FacebookEventsList";

class FacebookEvent extends Component {
	componentDidMount() {
		const { loadSDK, facebook } = this.props;
		if (!facebook.sdk.loaded && !facebook.sdk.loading) {
			loadSDK();
		}
	}
	render() {
		const { facebookSettings, facebook, publish, currentEvent } = this.props;
		const facebookConnected = !!(facebookSettings && facebookSettings.connection && facebookSettings.connection.userId);
		const isEventPublished = currentEvent?.event?.published;

		return (
			<LoadErrorView loading={facebook.sdk.loading || facebook.connectionCheck.loading}>
				<PageHeading
					title="Facebook events"
					helpButton={{
						link: HelpIds.facebookEvent,
						title: "Help - Facebook Event"
					}}
				/>
				{!facebookConnected ? (
					<ConnectFacebook />
				) : (
					<>
						{!isEventPublished ? (
							<div>
								<H3>Publish your event</H3>
								<p>Please publish your event before you add it to facebook</p>
								<LegacyButton onClick={() => publish(currentEvent.event._id)} type="primary" ariaLabel="Publish">
									Publish
								</LegacyButton>
							</div>
						) : (
							<FacebookEventsList />
						)}
					</>
				)}
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => {
		return {
			facebook: state.facebook,
			facebookSettings: state.auth.user.facebookSettings,
			currentEvent: state.currentEvent
		};
	},
	{ loadSDK, publish }
)(FacebookEvent);
