import { AlreadyHaveAccount } from "@/auth/components/Links";
import { SingleColumnPageWrap } from "@/auth/components/SingleColumnPageWrap";
import { H1, P } from "@/components/text/Text";
import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import { Button } from "@/ui/Button";
import { notification } from "antd";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router";

export const VerifyAccount: FC = () => {
	const navigate = useNavigate();
	const user = useAppSelector((state) => state.auth.user);

	const resendEmailVerificationProcedure = trpc.users.resendEmailVerification.useMutation();

	useEffect(() => {
		if (user.emailVerified) {
			navigate("/console/dashboard");
		}
	}, []);

	const resend = () => {
		resendEmailVerificationProcedure
			.mutateAsync()
			.then((response) => {
				notification.success({ message: "Sent", description: response.message });
			})
			.catch((err) => {
				notification.error({ message: "Failed", description: err.message });
			});
	};

	return (
		<SingleColumnPageWrap showBuyTicketLink>
			<div style={{ maxWidth: "400px", textAlign: "center" }}>
				<H1>Please verify your email</H1>
			</div>

			<P style={{ maxWidth: "100%", textAlign: "center" }}>
				An email has been sent to: <b>{user.email}</b>.
				<br />
				Please click the link in the email to verify your account.
			</P>

			<Button
				onClick={resend}
				variant="tertiary"
				htmlType="submit"
				fullWidth
				isLoading={resendEmailVerificationProcedure.isLoading}
				disabled={resendEmailVerificationProcedure.isLoading}
				aria-label="Submit"
				style={{
					marginBottom: 24
				}}
			>
				Resend verification email
			</Button>

			<AlreadyHaveAccount />
		</SingleColumnPageWrap>
	);
};
