import { H2, H4, P } from "@/components/text/Text";
import { Dayjs } from "@/libs/dayjs";
import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import { Collapse, DatePicker, Select, Table } from "@/ui/antd";
import { Button } from "@/ui/Button";
import { Dropdown } from "@/ui/Dropdown";
import { max } from "@/utils/CssUtils";
import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { SlidersHorizontal } from "@phosphor-icons/react";
import { useState } from "react";
import IconButton from "../IconButton";
import Widget from "./Widget";

const { Panel } = Collapse;

const titleStyle = css({
	display: "flex",
	justifyContent: "space-between",
	minWidth: "100%"
});

const contentStyle = css({
	display: "flex",
	justifyContent: "space-between",
	gap: 20,
	[max(768)]: {
		flexDirection: "column"
	}
});

const dynamicInfoStyle = css({
	width: "50%",
	textAlign: "center",
	[max(768)]: {
		width: "100%",
		paddingRight: 0
	}
});

const resaleContainer = css({
	borderRadius: 4,
	backgroundColor: "#f9f9fa",
	padding: "12px 0px 5px 0px",
	p: {
		padding: 0,
		margin: "0px auto"
	}
});

const byReportingGroupStyle = css({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	paddingTop: 20,
	paddingBottom: 10
});

const byTicketTypeContainer = css({
	marginTop: 20
});

const byReportingGroupContainer = css({
	padding: "50px 0 10px 0"
});

const expandedRowStylings = css`
	.ant-table {
		margin: 0;
	}
`;

const tableWrapper = css`
	.ant-table-expanded-row > .ant-table-cell {
		padding: 0;
	}
	.ant-table-expanded-row > .ant-table {
		margin: 0;
	}
`;

const tableRowColor = css`
	td {
		background-color: #f9f9fa;
	}

	tr {
		background-color: #f9f9fa;
	}
`;

const ticketCols = [
	{
		title: "Ticket type",
		dataIndex: "type",
		key: "type"
	},
	{
		title: "Resold",
		dataIndex: "resold",
		key: "resold"
	}
];

const Title = ({ statusColor, resaleOn }: { statusColor: string; resaleOn: boolean }) => (
	<div className={titleStyle}>
		<H2>Resale</H2>
		<div
			style={{
				display: "flex"
			}}
		>
			<span
				style={{
					backgroundColor: statusColor,
					height: 8,
					width: 8,
					borderRadius: "50%",
					marginRight: 4,
					marginTop: 5
				}}
			/>
			<P style={{ color: statusColor }}>Resale {resaleOn ? "on" : "off"}</P>
		</div>
	</div>
);

const BoxContent = ({ title, content }: { title: string; content: number }) => (
	<div className={dynamicInfoStyle}>
		<div className={resaleContainer}>
			<P>{title}</P>
			<H4>{content}</H4>
		</div>
	</div>
);

const DateChangeDropdown = ({
	showEventDateChange,
	defaultValue,
	eventDateOptions,
	handleEventDateChange,
	handleDateRangeChange
}: {
	showEventDateChange: boolean;
	defaultValue: string | null | undefined;
	eventDateOptions: { label: string; value: string }[];
	handleEventDateChange: (eventDateId: string) => void;
	handleDateRangeChange: (type: "startDate" | "endDate") => (date: Dayjs | null) => void;
}) => (
	<Dropdown
		persist
		title="Refine by resale date"
		items={
			<>
				<DatePicker
					id="startDate"
					name="startDate"
					onChange={handleDateRangeChange("startDate")}
					placeholder="Start Date"
					size="large"
					style={{ marginBottom: 12, width: "100%" }}
				/>
				<DatePicker
					id="endDate"
					name="endDate"
					onChange={handleDateRangeChange("endDate")}
					placeholder="End Date"
					size="large"
					style={{ width: "100%" }}
				/>
				{showEventDateChange && (
					<Select
						defaultValue={defaultValue}
						onChange={handleEventDateChange}
						options={eventDateOptions}
						style={{ height: "auto", wordWrap: "break-word", width: "100%", marginTop: 12 }}
					/>
				)}
			</>
		}
	>
		<Button iconOnly variant="text">
			<SlidersHorizontal size={20} />
		</Button>
	</Dropdown>
);

const resalesColumns = (onExpandClick?: (key: string) => void) => [
	{
		title: "Reporting group",
		dataIndex: "name",
		render: (name: string) => (
			<div style={{ paddingLeft: onExpandClick ? 0 : 24 }}>
				{onExpandClick ? <IconButton icon="arrow_down" ariaLabel="expand" onClick={() => onExpandClick(name)} /> : null}
				{onExpandClick ? <b>{name}</b> : name}
			</div>
		)
	},
	{
		title: "Resold",
		dataIndex: "resold",
		width: 150,
		render: (resales: any) => (onExpandClick ? <b>{resales}</b> : resales)
	}
];

export const ResoldTicketsCard = () => {
	const currentEvent = useAppSelector((state) => state.currentEvent);

	const event = currentEvent.event;
	const resaleSettings = event?.resaleSettings;

	const enabled = resaleSettings?.enabled;

	const [dateRange, setDateRange] = useState({
		startDate: undefined,
		endDate: undefined
	});
	const [eventDateId, setEventDateId] = useState<string | undefined>(undefined);
	const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

	const _onExpandClick = (key: string) => {
		const tempRowKeys = [...expandedRowKeys];
		if (tempRowKeys.includes(key)) {
			const index = tempRowKeys.indexOf(key);
			tempRowKeys.splice(index, 1);
		} else {
			tempRowKeys.push(key);
		}
		setExpandedRowKeys(tempRowKeys);
	};

	const resaleResults = trpc.metrics.resaleData.useQuery(
		{
			eventId: event?._id || ""
		},
		{ enabled }
	);

	const { data: ticketTypeResults } = trpc.metrics.ticketTypeResaleData.useQuery(
		{
			eventId: event?._id || "",
			startDate: dateRange.startDate
				? new HumaniDate(dateRange.startDate).dayJs.startOf("day").toISOString()
				: undefined,
			endDate: dateRange.endDate ? new HumaniDate(dateRange.endDate).dayJs.endOf("day").toISOString() : undefined,
			eventDateId
		},
		{ enabled }
	);

	if (!event?._id || !enabled) return null;

	const handleDateRangeChange = (type: "startDate" | "endDate") => (date: Dayjs | null) => {
		setDateRange((prev) => ({ ...prev, [type]: date?.toISOString() }));
	};

	const handleEventDateChange = (eventDateId: string) => {
		setEventDateId(eventDateId === "allDates" ? undefined : eventDateId);
	};

	const { dates = [] } = event;

	const resaleStartDate = resaleSettings?.startResaleDate ? new Date(resaleSettings.startResaleDate) : null;
	const resaleEndDate = resaleSettings?.endResaleDate ? new Date(resaleSettings.endResaleDate) : null;
	const currentDateTime = new Date();
	const resaleHasStarted = resaleStartDate ? resaleStartDate < currentDateTime : true;
	const resaleHasNotEnded = resaleEndDate ? resaleEndDate > currentDateTime : true;
	const resaleOn = resaleHasStarted && resaleHasNotEnded;

	const tableData = ticketTypeResults?.result.ticketTypeData || [];
	const reportingGroupsData = ticketTypeResults?.result.reportingGroupsData;

	const resoldTickets = resaleResults?.data?.result?.resoldTickets || 0;
	const ticketsWithResale = resaleResults?.data?.result?.ticketsWithResale || 0;

	const statusColor = resaleOn ? "#157e77" : "#c0212c";

	const eventDateOptions = [{ label: "All event dates", value: "allDates" }].concat(
		dates.map((eventDate) => ({
			label: eventDate.display ? eventDate.display.combined : String(eventDate.startDate),
			value: eventDate._id
		}))
	);

	const reportingGroupsExist = !!event.reportingGroups?.length;

	return (
		<Widget>
			<div>
				<Title statusColor={statusColor} resaleOn={resaleOn} />
				<div className={contentStyle}>
					<BoxContent title="Resold tickets" content={resoldTickets} />
					<BoxContent title="Tickets up for resale" content={ticketsWithResale} />
				</div>
			</div>
			{reportingGroupsExist && (
				<div className={byReportingGroupContainer}>
					<div className={byReportingGroupStyle}>
						<H4 style={{ marginBottom: 0 }}>Resales by reporting groups</H4>
						<DateChangeDropdown
							showEventDateChange={dates.length > 1}
							defaultValue={eventDateOptions[0].value}
							eventDateOptions={eventDateOptions}
							handleEventDateChange={handleEventDateChange}
							handleDateRangeChange={handleDateRangeChange}
						/>
					</div>

					<div className={tableWrapper}>
						<Table
							columns={resalesColumns(_onExpandClick)}
							dataSource={reportingGroupsData ?? []}
							rowKey="name"
							expandable={{
								expandedRowRender: (group) => {
									return (
										<div className={expandedRowStylings}>
											<Table
												columns={resalesColumns()}
												dataSource={group.byTicket}
												pagination={false}
												showHeader={false}
												rowKey="name"
												rowClassName={(_record, index) => {
													if (index % 2 === 0) {
														return "";
													}
													return tableRowColor;
												}}
											/>
										</div>
									);
								},
								expandedRowKeys,
								expandIconColumnIndex: -1
							}}
							pagination={false}
						/>
					</div>
				</div>
			)}
			<div className={byTicketTypeContainer}>
				<Collapse
					ghost
					expandIcon={(panelProps) =>
						panelProps.isActive ? (
							<IconButton icon="arrow_up" ariaLabel="close" expanded={true} small={true} style={{ marginRight: 8 }} />
						) : (
							<IconButton
								icon="arrow_down"
								ariaLabel="expand"
								expanded={false}
								small={true}
								style={{ marginRight: 8 }}
							/>
						)
					}
				>
					<Panel
						header={<H4>Resales by ticket type</H4>}
						key="1"
						collapsible="icon"
						extra={
							!reportingGroupsExist && (
								<DateChangeDropdown
									showEventDateChange={dates.length > 1}
									defaultValue={eventDateOptions[0].value}
									eventDateOptions={eventDateOptions}
									handleEventDateChange={handleEventDateChange}
									handleDateRangeChange={handleDateRangeChange}
								/>
							)
						}
					>
						<Table pagination={false} columns={ticketCols} dataSource={tableData} rowKey="id" />
					</Panel>
				</Collapse>
			</div>
		</Widget>
	);
};
