import { FormLabel, ResponsiveModal } from "@/ui";
import { Col, Form, Input, Space } from "@/ui/antd";
import { Collection, SocialLinkDomains } from "@hx/console";
import { FC, useEffect } from "react";

const socialLinkDomains: Partial<Record<SocialLinkDomains, string>> = {
	facebook: "https://www.facebook.com/",
	x: "https://www.x.com/",
	instagram: "https://www.instagram.com/",
	linkedIn: "https://www.linkedin.com/",
	spotify: "https://www.spotify.com/",
	soundcloud: "https://www.soundcloud.com/",
	tiktok: "https://www.tiktok.com/"
};

type SocialLinkModalProps = {
	initialValues: Partial<Collection["info"]["socialLinks"][0]>;
	onCancel: () => void;
	onSubmit: (values: Collection["info"]["socialLinks"][0]) => void;
	open: boolean;
};

export const SocialLinkModal: FC<SocialLinkModalProps> = ({ open, initialValues, onCancel, onSubmit }) => {
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(initialValues);
	}, [initialValues]);

	const handleFinish = (values: Collection["info"]["socialLinks"][0]) => {
		onSubmit(values);
		form.resetFields();
	};

	const isCustom = initialValues.type === "custom";
	const rootDomain = (isCustom ? "" : socialLinkDomains[initialValues.type ?? "custom"]) ?? "";
	const rootDomainWithoutProtocol = rootDomain.replace("https://", "");
	const rootDomainInsecure = rootDomain.replace("https://", "http://");
	const stripValues = [rootDomain, rootDomainWithoutProtocol, rootDomainInsecure].filter(Boolean);

	return (
		<ResponsiveModal
			header={initialValues.url ? "Edit link" : "Add a link"}
			open={open}
			primaryButtonAction={form.submit}
			secondaryButtonAction={onCancel}
			width="600px"
		>
			<Form form={form} layout="vertical" onFinish={handleFinish}>
				<Form.Item name="type" hidden>
					<Input />
				</Form.Item>
				<Space direction="vertical" size={24} style={{ width: "100%" }}>
					<Col>
						<FormLabel required>{initialValues.type === "custom" ? "URL" : `${initialValues.name} URL`}</FormLabel>
						<Form.Item
							name="url"
							normalize={(value: string) => {
								value = value.trim();
								stripValues.forEach((stripValue) => {
									while (value && value.startsWith(stripValue)) {
										value = value.replace(stripValue, "");
									}
								});
								return value;
							}}
							rules={[
								{ max: 1000, message: "URL must be less than 1000 characters" },
								{
									validator: async (_, value) => {
										if (isCustom && !(value?.startsWith("http://") || value?.startsWith("https://"))) {
											return Promise.reject("Please enter a valid URL - Example: https://www.humanitix.com");
										}
										return Promise.resolve();
									}
								}
							]}
							style={{ marginBottom: 0 }}
						>
							<Input addonBefore={socialLinkDomains[initialValues.type ?? "custom"]} maxLength={1000} />
						</Form.Item>
					</Col>
					<Col hidden={initialValues.type !== "custom"}>
						<FormLabel required>Name your link</FormLabel>
						<Form.Item
							name="name"
							style={{ marginBottom: 0 }}
							rules={[
								{ required: true, message: "Please enter a name for your link" },
								{ max: 80, message: "Name must be less than 80 characters" }
							]}
						>
							<Input maxLength={80} showCount />
						</Form.Item>
					</Col>
				</Space>
			</Form>
		</ResponsiveModal>
	);
};
