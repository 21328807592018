import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import LocationSelection from "../../components/LocationSelection";
import PageHeading from "../../components/PageHeading";
import LinkButton from "../../components/buttons/LinkButton";
import AdminReportService from "../../services/AdminReportService";
import LocationService from "../../services/LocationService";
import { getLocationFromState } from "../../state/actions/locationActions";
import FileDownload from "../../utils/FileDownload";

const tableCss = css`
	.capitalize {
		text-transform: capitalize;
	}
`;

class GeneratedReports extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
	}

	state = {
		loading: true,
		uploads: []
	};

	componentDidMount() {
		this.load();
	}

	load() {
		const { userId } = this.props;
		const location = getLocationFromState(true);

		this.setState({ loading: true }, () => {
			AdminReportService.getGeneratedReports(userId, location)
				.then((uploads) => {
					this.setState({ loading: false, uploads });
				})
				.catch(() => {
					this.setState({ loading: false, error: "Oops" });
				});
		});
	}

	async downloadReport(uploadId, reportType, createdAt) {
		const { userId } = this.props;

		const data = await AdminReportService.downloadGeneratedReport(userId, uploadId);
		FileDownload.saveAs(data, `${reportType}-${createdAt}.csv`);
	}

	formatDate(date) {
		return new HumaniDate(date, "guess", LocationService.getLocation(true)).formatting.dateTime();
	}

	sentenceCaseToWords(sentenceCase) {
		return sentenceCase.replace(/([A-Z])/g, " $1");
	}

	getFilterString(filters) {
		if (!filters?.admin) return "";
		delete filters.admin._id;

		return (
			<div>
				{Object.entries(filters.admin).map(([key, value]) => {
					const formattedValue = Date.parse(value) ? this.formatDate(value) : value;

					return (
						<div>
							<b>{this.sentenceCaseToWords(key)}</b>: {formattedValue}
							<br />
						</div>
					);
				})}
			</div>
		);
	}

	render() {
		return (
			<>
				<PageHeading title="Generated Reports">
					<LocationSelection componentName="generatedReport" defaultLevel="user" onLocationChange={this.load} />
				</PageHeading>
				<ContentBlock>
					<LoadErrorView loading={this.state.loading} retryAction={this.load} error={this.state.error}>
						{!this.state.loading && (
							<>
								<p>Reports generated over the last 90 days</p>
								<Table
									columns={[
										{
											title: "Report Type",
											dataIndex: "reportType",
											key: "reportType",
											className: "capitalize",
											render: (reportType) => this.sentenceCaseToWords(reportType)
										},
										{
											title: "Filters",
											dataIndex: "filters",
											key: "filters",
											render: (filters) => this.getFilterString(filters)
										},
										{
											title: "Generated At",
											dataIndex: "createdAt",
											key: "createdAt",
											render: (date) =>
												new HumaniDate(date, "guess", LocationService.getLocation(true)).formatting.dateTime()
										},
										{
											title: "Download",
											dataIndex: "url",
											key: "url",
											render: (url, upload) => {
												return (
													<LinkButton
														onClick={() => this.downloadReport(upload._id, upload.reportType, upload.createdAt)}
														style={{ textDecoration: "underline" }}
													>
														Download
													</LinkButton>
												);
											}
										}
									]}
									dataSource={this.state.uploads}
									className={tableCss}
									locale={{ emptyText: "No generated reports" }}
									rowKey="_id"
									scroll={{ x: "max-content" }}
									pagination={false}
								/>
							</>
						)}
					</LoadErrorView>
				</ContentBlock>
			</>
		);
	}
}

export default connect((state) => {
	return { userId: state.auth.user.id };
})(GeneratedReports);
